import { capitalize } from '~utils/strings'

export const parseAccountRequest = (account, updatedAccountPictureURL) => {
  return {
    account: {
      ...account,
      ...(updatedAccountPictureURL && { account_picture: updatedAccountPictureURL })
    }
  }
}
export const isAccountPictureUpdated = ({ account_picture: accountPicture }) => {
  return accountPicture && accountPicture.startsWith('data:')
}

export const parseAccountPictureRequest = ({ account_picture: accountPicture }) => {
  const base64File = accountPicture.split(',')[1]
  const image = `not_save=true&img=${base64File}`

  return image
}

export const parseAccountCountriesOptions = (countries) => {
  const { data } = countries

  return data.map(country => {
    const { attributes } = country
    const { name, alpha2 } = attributes

    return { label: name, value: alpha2 }
  })
}

export const parseAccountTimezoneOptions = (timezones) => {
  const { data } = timezones

  return data.map(timezone => {
    const { id, attributes } = timezone
    const { name } = attributes

    return { label: name, value: id }
  })
}

export const parseAccountLanguagesOptions = (languages) => {
  const { data } = languages

  return data.map(language => {
    const { id, attributes } = language
    const { name } = attributes

    return { label: name, value: id }
  })
}

export const parseAccountError = (error, action) => {
  const { response } = error
  const { status, statusText } = response

  return {
    status,
    statusText,
    action
  }
}

export const parseAccountErrorForm = ({ resource: errorResponse } = {}) => {
  let errors = {}
  Object.entries(errorResponse).forEach(([field, text]) => {
    errors = { ...errors, [field]: capitalize(text[0]) }
  })
  return errors
}
