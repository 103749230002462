import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import normalizeJsonAPI from '@beetrack/jsonapi-normalizer'
import * as api from '~api/settings/forms'
import { storeEntities, replaceEntities } from '../entities'
import { ENTITY, getEntity } from '~selectors/entities'
import { openSnackbar } from '~actions/layout'
import i18n from '~localization/index'
import { selectActivityTypesOptions } from '~selectors/settings/forms'
import {
  parseNormalizedPresentation,
  parsePresentationPayload,
  parseActivityPayload,
  parseNormalizedStatusesSubStatusesGroups,
  parseProofOfDeliveriesError,
  parsePresentationGroupOptions
} from '~parsers/proof_of_deliveries'

const NAMESPACE = 'PROOF_OF_DELIVERY'

export const createDefaultSections = createAsyncThunk(`${NAMESPACE}/createDefaultSections`,
  async (_, { dispatch }) => {
    try {
      const { data } = await api.createDefaultSections()
      const normalized = normalizeJsonAPI(data, true)
      dispatch(storeEntities(normalized))
      return Object.keys(normalized.entities[ENTITY.proofOfDeliveriesPresentation])
    } catch (error) {
      console.error(error)
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:proof-of-deliveries.error-default-section')
      }))
      return Promise.reject(error)
    }
  })

export const fetchProofOfDeliveries = createAsyncThunk(
  `${NAMESPACE}/fetchProofOfDelivery`,
  async (_, { dispatch }) => {
    try {
      await dispatch(fetchActivityTypes()).then(unwrapResult)
      const { data } = await api.fetchProofOfDelivery()
      if (!data.data) return []
      const normalized = normalizeJsonAPI(data, true)
      dispatch(storeEntities(normalized))
      dispatch(storeEntities(parseNormalizedStatusesSubStatusesGroups(normalized.entities)))
      const evaluationId = normalized.result[ENTITY.proofOfDeliveriesEvaluation][0]
      const presentationId = normalized.entities[ENTITY.proofOfDeliveriesEvaluation][evaluationId].presentations
      return presentationId
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
)

export const fetchStatus = createAsyncThunk(
  `${NAMESPACE}/fetchSubStatuses`,
  async (_, { dispatch }) => {
    try {
      const { data } = await api.fetchDispatchStatuses()
      const normalized = normalizeJsonAPI(data)
      dispatch(storeEntities(normalized))
      return normalized.result[ENTITY.status]
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  })

export const resetActivityDispatchSubStatus = createAction(`${NAMESPACE}/resetActivityDispatchSubStatus`)

const fetchDispatchSubStatus = async (params, { dispatch }) => {
  try {
    const response = await api.fetchDispatchSubStatuses(params)

    const normalized = normalizeJsonAPI(response.data, true)
    dispatch(storeEntities(normalized))
    return {
      ids: normalized.result?.[ENTITY.subStatus] || [],
      meta: response.data.meta
    }
  } catch (error) {
    console.error(error)
    return Promise.reject(error)
  }
}

export const fetchActivityDispatchSubStatus = createAsyncThunk(
  `${NAMESPACE}/fetchActivityDispatchSubStatus`, fetchDispatchSubStatus
)

export const fetchPresentationDispatchSubStatus = createAsyncThunk(
  `${NAMESPACE}/fetchPresentationDispatchSubStatus`, fetchDispatchSubStatus
)

export const createPresentation = createAsyncThunk(
  `${NAMESPACE}/createPresentation`,
  async ({ ...params }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.createNewPresentation(parsePresentationPayload(params))
      const normalized = normalizeJsonAPI(data, true)
      dispatch(storeEntities(normalized))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:proof-of-deliveries.create-success')
      }))
      return normalized.result[ENTITY.proofOfDeliveriesPresentation]
    } catch (error) {
      console.error(error)
      if (error?.response?.status === 422) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:proof-of-deliveries.fields-error')
        }))
        return rejectWithValue(parseProofOfDeliveriesError(error?.response?.data))
      }
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:proof-of-deliveries.generic-error')
      }))
      return Promise.reject(error)
    }
  }
)

export const updatePresentation = createAsyncThunk(
  `${NAMESPACE}/updatePresentation`,
  async ({ id, ...params } = {}, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.updatePresentation(id, parsePresentationPayload(params))
      const normalized = normalizeJsonAPI(data, true)
      dispatch(storeEntities(normalized))
      dispatch(storeEntities(parseNormalizedStatusesSubStatusesGroups(normalized.entities)))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:proof-of-deliveries.update-success')
      }))
    } catch (error) {
      console.error(error)
      if (error?.response?.status === 422) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:proof-of-deliveries.fields-error')
        }))
        return rejectWithValue(parseProofOfDeliveriesError(error?.response?.data))
      }
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:proof-of-deliveries.generic-error')
      }))
      return Promise.reject(error)
    }
  }
)

export const deletePresentation = createAsyncThunk(`${NAMESPACE}/deletePresentation`,
  async ({ id }, { dispatch }) => {
    try {
      await api.deletePresentation(id)
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:proof-of-deliveries.delete-success')
      }))
    } catch (error) {
      console.error(error)
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:proof-of-deliveries.generic-error')
      }))
      return Promise.reject(error)
    }
  })

export const fetchActivityTypes = createAsyncThunk(
  `${NAMESPACE}/fetchActivityTypes`,
  async (_, { dispatch, getState }) => {
    try {
      const activityTypes = selectActivityTypesOptions(getState())
      if (activityTypes.length > 0) return

      const data = await api.fetchActivityTypes()
      const normalized = normalizeJsonAPI(data.data, true)
      dispatch(storeEntities(normalized))
      return normalized.result[ENTITY.activityType] || []
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
)

export const createActivity = createAsyncThunk(
  `${NAMESPACE}/createActivity`,
  async ({ presentationId, duplicate, ...params }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await api.createActivity(presentationId, parseActivityPayload(presentationId, params, duplicate))
      const normalized = normalizeJsonAPI(response.data, true)
      const parentPresentation = getEntity(ENTITY.proofOfDeliveriesPresentation, presentationId)(getState())
      dispatch(storeEntities(normalized))
      dispatch(storeEntities(parseNormalizedPresentation(
        parentPresentation,
        { activities: [
          ...parentPresentation?.activities || [],
          ...normalized.result?.[ENTITY.proofOfDeliveriesActivity] || [],
          ...normalized.result?.[ENTITY.choiceActivity] || []
        ] }
      )))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:proof-of-deliveries.activities.create-success')
      }))
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:proof-of-deliveries.fields-error')
        }))
      } else {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:proof-of-deliveries.generic-error')
        }))
      }
      return rejectWithValue({})
    }
  }
)

export const updateActivity = createAsyncThunk(
  `${NAMESPACE}/updateActivity`,
  async ({ id, presentationId, ...params } = {}, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateActivity(
        presentationId, id, parseActivityPayload(presentationId, { id, ...params }))
      const normalized = normalizeJsonAPI(response.data, true)
      dispatch(replaceEntities(normalized))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:proof-of-deliveries.activities.update-success')
      }))
    } catch (error) {
      console.error(error)
      if (error?.response?.status === 422) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:proof-of-deliveries.fields-error')
        }))
      } else {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:proof-of-deliveries.generic-error')
        }))
      }
      return rejectWithValue({})
    }
  }
)

export const deleteActivity = createAsyncThunk(
  `${NAMESPACE}/deleteActivity`,
  async ({ presentationId, id }, { dispatch, getState }) => {
    try {
      await api.deleteActivity(presentationId, id)
      const parentPresentation = getEntity(ENTITY.proofOfDeliveriesPresentation, presentationId)(getState())
      dispatch(storeEntities(parseNormalizedPresentation(
        parentPresentation,
        { activities: [
          ...(parentPresentation?.activities || []).filter(activityId => activityId !== id)
        ] }
      )))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:proof-of-deliveries.activities.delete-success')
      }))
    } catch (error) {
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:proof-of-deliveries.generic-error')
      }))
      console.error(error)
      return Promise.reject(error)
    }
  }
)

export const createDefaultActivity = createAsyncThunk(
  `${NAMESPACE}/createDefaultActivity`,
  async (_, { dispatch }) => {
    try {
      const { data } = await api.createDefaultActivities()
      const normalized = normalizeJsonAPI(data, true)
      dispatch(storeEntities(normalized))
      return null
    } catch (error) {
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:proof-of-deliveries.info-box.generic-error')
      }))
      console.error(error)
      return Promise.reject(error)
    }
  }
)

export const updatePositionActivity = createAsyncThunk(
  `${NAMESPACE}/updatePositionActivity`,
  async ({ presentationId, activityId, dataPosition }, { dispatch }) => {
    try {
      const { data } = await api.calculatePositionActivity(presentationId, activityId, dataPosition)
      const normalized = normalizeJsonAPI(data, true)
      dispatch(storeEntities(normalized))
    } catch (error) {
      console.error(error)
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:proof-of-deliveries.generic-error')
      }))
      return Promise.reject(error)
    }
  }
)

export const showLosingChangesWarning = (dispatch) => {
  dispatch(openSnackbar({
    type: 'alert',
    message: i18n.t('pages:proof-of-deliveries.losing-changes-warning')
  }))
}

const fetchGroups = async (args = {}, { dispatch }) => {
  try {
    const response = await api.fetchGroups(args.params)
    const normalizedData = normalizeJsonAPI(response.data)
    const options = parsePresentationGroupOptions(normalizedData)

    dispatch(storeEntities(normalizedData))
    return { ...normalizedData, options, meta: { event: args.event || 'default', ...response.data.meta } }
  } catch (error) {
    console.error(error)
    return Promise.reject(error)
  }
}

export const fetchPresentationGroups = createAsyncThunk(
  `${NAMESPACE}/fetchPresentationGroups`, fetchGroups
)
