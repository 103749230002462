import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { NotFoundState } from '@beetrack/hp-components'

import { selectWidgetConfig } from '~selectors/widget_result'

import { WidgetResultFooter } from '.'

export const WidgetResultEmptyState = () => {
  const { t } = useTranslation()
  const { logo_url: logo, not_found_msg: notFoundMsg } = useSelector(selectWidgetConfig)

  return (
    <div className='widget-result-empty-state'>
      <div className='widget-result-empty-state__logo' ><img src={logo} /></div>
      <NotFoundState
        title={t('components:widget-result.empty-state.title')}
        text={notFoundMsg || t('components:widget-result.empty-state.text')} />
      <WidgetResultFooter />
    </div>
  )
}

export default WidgetResultEmptyState
