import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray, useFormikContext } from 'formik'

import { getProperty } from '~utils'

const FormikRepetitive = ({ name, children, addButtonRender, objectBuilder, header }) => {
  const { values } = useFormikContext()
  const arrayValues = getProperty(values, name) || []
  const count = arrayValues.length || 0

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <>
          {header}
          {arrayValues.map((item, index) => (
            <React.Fragment key={`${name}-${index}`}>
              {children({
                item,
                index,
                count,
                deleteItem: () => arrayHelpers.remove(index),
                itemField: (objectKey) => `${name}.${index}.${objectKey}`
              })}
            </React.Fragment>
          ))}
          {addButtonRender({
            count,
            addItem: () => arrayHelpers.insert(count, objectBuilder({ count: count + 1 }))
          })}
        </>
      )}
    />
  )
}

FormikRepetitive.defaultProps = {
  children: () => {},
  addButtonRender: () => {},
  values: [],
  objectBuilder: () => ({})
}

FormikRepetitive.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func,
  addButtonRender: PropTypes.func,
  objectBuilder: PropTypes.func,
  header: PropTypes.node
}

export default FormikRepetitive
