import { createReducer } from '@reduxjs/toolkit'
import { fetchRoutes } from '../../actions/temporal_link/routes'

import {
  createInitialState,
  handlePendingAsyncThunk,
  handleFulfilledAsyncThunk,
  handleRejectedAsyncThunk
} from '~utils/async_thunk_factory'

const INITIAL_STATE = {
  fetchRoutes: createInitialState(),
  availableResources: []
}

export const routesReducer = createReducer(INITIAL_STATE, {
  [fetchRoutes.pending]: (state, action) => {
    state.fetchRoutes = handlePendingAsyncThunk(state, action)
  },
  [fetchRoutes.fulfilled]: (state, action) => {
    state.fetchRoutes = handleFulfilledAsyncThunk(state, action)
  },
  [fetchRoutes.rejected]: (state, action) => {
    state.fetchRoutes = handleRejectedAsyncThunk(state, action)
  }
})

export default routesReducer
