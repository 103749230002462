import { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useWindowSize } from '.'

import { getSidebarData } from '~selectors/layout'
import { sidebarItems, MOBILE_BREAKPOINT_FOR_SIDEBAR } from '~components/layout/sidebar_items'

export const useSidebarData = () => {
  const { allowedItems: allowedItemsByPermission,
    isLoading, ...rest } = useSelector(getSidebarData(sidebarItems))
  const [width] = useWindowSize()
  const [allowedItems, setAllowedItems] = useState([])

  useLayoutEffect(() => {
    if (width < MOBILE_BREAKPOINT_FOR_SIDEBAR) {
      setAllowedItems(allowedItemsByPermission.filter(item => !item.mobileHidden))
    } else {
      setAllowedItems(allowedItemsByPermission)
    }
  }, [isLoading, width])

  return {
    allowedItems,
    isLoading,
    ...rest
  }
}
