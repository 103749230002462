import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Field } from 'formik'
import { DateRangePicker } from '@beetrack/hp-components'

import { getDark } from '~selectors/layout'

const FormikDateRangePicker = (props) => {
  const { name, label, disabled, ...otherProps } = props
  const dark = useSelector(getDark)
  return (
    <Field name={name}>
      {({ form, field, meta }) => {
        const { setFieldValue } = form
        const { error, touched } = meta
        const fieldDisabled = disabled || form.isSubmitting
        const hasInteraction = !fieldDisabled && (touched || form.submitCount > 0)

        return (
          <DateRangePicker
            dark={dark}
            label={label}
            onChange={(value) => setFieldValue(name, value)}
            from={field.value?.from || ''}
            to={field.value?.to || ''}
            error={hasInteraction && !!error}
            helperText={(hasInteraction && error) || ''}
            disabled={fieldDisabled}
            {...otherProps}
          />
        )
      }}
    </Field>
  )
}

FormikDateRangePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

FormikDateRangePicker.defaultProps = {
  disabled: false
}

export default FormikDateRangePicker
