import React from 'react'
import { useSelector } from 'react-redux'

import { FilterBar } from '@beetrack/hp-components'

import { getDark } from '~selectors/layout'
import { deleteObjectEmptyValues } from '~utils'
import { EVENT_TYPES } from '~constants'

const PageFilter = props => {
  const { value, updateData, children } = props
  const dark = useSelector(getDark)

  const handleFilter = (selectedFilter) => {
    const filter = deleteObjectEmptyValues(selectedFilter)

    const params = {
      page: 1,
      filter
    }

    updateData(EVENT_TYPES.FILTERING, params)
  }

  return (
    <FilterBar
      dark={dark}
      additionalClassName='page-filter'
      value={JSON.parse(JSON.stringify(value || {}))}
      onFilter={handleFilter}
    >
      {children}
    </FilterBar>
  )
}

export default PageFilter
