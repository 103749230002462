import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'

import store from '~reducers/index'

import i18next from '~localization'

import WidgetApp from 'frontend/widget_app'

document.addEventListener('DOMContentLoaded', () => {
  const mountNode = document.getElementById('widget_result')
  if (mountNode) {
    const data = JSON.parse(mountNode.getAttribute('data'))
    ReactDOM.render(
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <WidgetApp widgetData={data} />
        </I18nextProvider>
      </Provider>,
      mountNode
    )
    mountNode.removeAttribute('data')
  }
})
