export const asyncThunkInitialStateFactory = (actionName) => ({
  [actionName]: {
    ids: null,
    loading: false,
    error: false,
    indexLoadingItems: [],
    meta: {
      totalElements: 0
    }
  }
})

export const asyncThunkActionCreatorsFactory = (actions) => (actionName, { infinitePagination } = {}) => ({
  [actions[actionName].pending]: (state, action) => {
    state[actionName].loading = true
    state[actionName].error = false
  },
  [actions[actionName].fulfilled]: (state, action) => {
    const { meta, ids: addThisIds } = action.payload || {}
    state[actionName].loading = false
    state[actionName].error = false
    state[actionName].meta = action.payload.meta
    if (state[actionName].ids === null) {
      state[actionName].ids = {}
    }
    let baseIndex = 0
    if (addThisIds.length === 0 && meta.currentPage === 1) state[actionName].ids = {}
    if (infinitePagination && meta) {
      baseIndex = (meta.currentPage - 1) * meta.objectsPerPage
    }
    Array.isArray(addThisIds) && addThisIds.forEach((entityId, index) => {
      state[actionName].ids[baseIndex + index] = `${entityId}`
    })
  },
  [actions[actionName].rejected]: (state, action) => {
    state[actionName].loading = false
    state[actionName].error = true
  }
})

export const resetIds = (currentIDs, pageSize, excludedPages = []) =>
  Object.keys(currentIDs).map((key, index) => {
    const indexToPage = Math.ceil((index + 1) / pageSize)
    return excludedPages.includes(indexToPage) ? currentIDs[key] : undefined
  })

export const derivedStatus = (status) => ({
  isLoading: status === 'LOADING',
  isDone: status === 'SUCCESS',
  isError: status === 'FAILURE'
})

export const createInitialState = () => ({
  data: undefined,
  status: derivedStatus('IDLE'),
  error: undefined
})

export const handlePendingAsyncThunk = (state, action) => ({
  status: derivedStatus('LOADING'),
  error: undefined
})
export const handleFulfilledAsyncThunk = (state, action) => ({
  data: action.payload,
  status: derivedStatus('SUCCESS'),
  error: undefined
})
export const handleRejectedAsyncThunk = (state, action) => ({
  data: state.data,
  status: derivedStatus('FAILURE'),
  error: action.payload
})
