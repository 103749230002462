import React from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Calendar, Clock, User, CheckSquare, Envelope, Pin } from '@beetrack/hp-icons-react'

import { selectWidgetConfig } from '~selectors/widget_result'

import { DATE_TIME_FORMAT_API, DATE_FORMAT, TIME_FORMAT } from 'frontend/constants'

export const WidgetResultInformationData = (props) => {
  const { address, contact, maxDeliveryTime, estimatedAt, arrivedAt,
    numberOfPendingDeliveryPoints, showPendingDeliveryPoints, isManaged } = props
  const { permissions } = useSelector(selectWidgetConfig)
  const { t } = useTranslation()

  const getManagementDate = () => {
    if (!estimatedAt) return
    const date = DateTime.fromFormat(estimatedAt, DATE_TIME_FORMAT_API)
    const from = date.minus({ hours: 1, minutes: 30 }).toFormat(TIME_FORMAT)
    const to = date.plus({ hours: 1, minutes: 30 }).toFormat(TIME_FORMAT)
    return t('components:widget-result.information.estimated-time-sub-title', { from, to })
  }

  const dataInformation = [
    {
      icon: <Calendar />,
      title: t('components:widget-result.information.scheduled-date'),
      subTitle: DateTime.fromISO(maxDeliveryTime).toFormat(DATE_FORMAT),
      show: maxDeliveryTime && !isManaged
    },
    {
      icon: <Calendar />,
      title: t('components:widget-result.information.management-date'),
      subTitle: arrivedAt && DateTime.fromFormat(arrivedAt, DATE_TIME_FORMAT_API).toFormat(DATE_FORMAT),
      description: '',
      show: arrivedAt && isManaged
    },
    {
      icon: <Clock />,
      title: t('components:widget-result.information.estimated-time'),
      subTitle: getManagementDate(),
      description: t('components:widget-result.information.estimated-time-description'),
      show: permissions?.estimated_at && !isManaged
    },
    {
      icon: <User />,
      title: t('components:widget-result.information.client-name'),
      subTitle: contact.name,
      show: contact.name && permissions?.contact
    },
    {
      icon: <CheckSquare />,
      title: t('components:widget-result.information.contact-identifier'),
      subTitle: contact.identifier,
      show: contact.identifier && permissions?.contact
    },
    {
      icon: <Envelope />,
      title: t('components:widget-result.information.email'),
      subTitle: contact.email,
      show: contact.email && permissions?.contact
    },
    {
      icon: <Pin />,
      title: t('components:widget-result.information.destination-address'),
      subTitle: address.name,
      show: address.name && permissions?.contact
    },
    {
      icon: <Pin />,
      title: t('components:widget-result.information.pending-delivery-points'),
      subTitle: numberOfPendingDeliveryPoints,
      show: showPendingDeliveryPoints
    }
  ]

  return (
    <>
      {dataInformation.map((result, index) =>
        result.show && (
          <div key={index} className='widget-result-information__data'>
            <div className='widget-result-information__icon'>
              {result.icon}
            </div>
            <div className='widget-result-information__content'>
              <label className='widget-result-information__title'> {result.title}</label>
              <label className='widget-result-information__sub-title'> {result.subTitle}</label>
              <label className='widget-result-information__description'> {result.description}</label>
            </div>
          </div>)
      )}
    </>
  )
}
