import { createReducer } from '@reduxjs/toolkit'
import * as actions from '~actions/settings/account'
import { handleFetchReducer, handleFetchSelectOptions } from '~reducers/shared'

const INITIAL_STATE = {
  currentAccountId: null,
  loading: null,
  error: null,
  defaultAccountPicture: null,
  countryOptions: {
    options: [],
    loading: null
  },
  languageOptions: {
    options: [],
    loading: null
  },
  timezoneOptions: {
    options: [],
    loading: null
  }
}

export const accountReducer = createReducer(INITIAL_STATE, builder =>
  builder
    .addCase(actions.fetchAccount.fulfilled, (state, action) => {
      const { payload } = action
      const { meta = {} } = payload

      state.loading = null
      state.currentAccountId = payload?.result?.account[0]
      state.defaultAccountPicture = meta['default_picture_url']
    })
    .addMatcher(...handleFetchReducer(actions.fetchAccount))
    .addMatcher(...handleFetchReducer(actions.updateAccount))
    .addMatcher(...handleFetchSelectOptions(actions.fetchAccountCountryOptions, 'countryOptions'))
    .addMatcher(...handleFetchSelectOptions(actions.fetchAccountTimezoneOptions, 'timezoneOptions'))
    .addMatcher(...handleFetchSelectOptions(actions.fetchAccountLanguageOptions, 'languageOptions'))
)

export default accountReducer
