import React from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'formik'

import { getDark } from '~selectors/layout'
import AddressInput from '~components/address_input'

export const FormikAddressInput = (props) => {
  const { onSelect, disabled, ...otherProps } = props
  const dark = useSelector(getDark)
  return (
    <Field name={props.name}>
      {({ field, form }) => {
        const fieldDisabled = disabled || form.isSubmitting

        return (
          <AddressInput
            dark={dark}
            onSelect={onSelect}
            onChange={form.handleChange}
            value={field.value}
            disabled={fieldDisabled}
            {...otherProps}
          />
        )
      }}
    </Field>
  )
}

export default FormikAddressInput
