import { createReducer } from '@reduxjs/toolkit'
import * as actions from '~actions/settings/widget'

import { fetchInitialState, handleFetchReducer,
  handlePageFulfilled, pagedInitialState, handleFetchSelectOptions } from '~reducers/shared'

const INITIAL_STATE = {
  ...fetchInitialState,
  ...pagedInitialState,
  widget: null,
  indexLoadingItems: [],
  visits: {
    ...fetchInitialState,
    ...pagedInitialState,
    indexLoadingItems: []
  },
  conditions: {
    options: [],
    loading: false
  },
  groups: {
    options: [],
    loading: false
  }
}

export const widgetReducer = createReducer(INITIAL_STATE, builder =>
  builder
    .addCase(actions.deleteWidget.pending, (state, { meta: { arg: { deleteItems } } }) => {
      const loadingItemsIndex = deleteItems.map(({ index }) => index)
      state.indexLoadingItems = loadingItemsIndex
    })
    .addCase(actions.deleteWidget.fulfilled, (state, action) => {
      state.indexLoadingItems = []
    })
    .addCase(actions.deleteWidget.rejected, (state, action) => {
      state.indexLoadingItems = []
    })
    .addMatcher(...handleFetchReducer(actions.fetchWidgets))
    .addMatcher(...handlePageFulfilled(actions.fetchWidgets))
    .addMatcher(...handleFetchReducer(actions.createWidget))
    .addMatcher(...handleFetchReducer(actions.getWidget))
    .addMatcher(...handleFetchReducer(actions.updateWidget))
    .addMatcher(...handleFetchSelectOptions(actions.fetchWidgetConditions, 'conditions'))
    .addMatcher(...handleFetchSelectOptions(actions.fetchWidgetGroups, 'groups'))
    .addMatcher(...handleFetchReducer(actions.fetchWidgetTraffic, 'visits'))
    .addMatcher(...handlePageFulfilled(actions.fetchWidgetTraffic, 'visits'))
)

export default widgetReducer
