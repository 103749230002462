
import axios from '../config'

export const fetchWebhooks = () => {
  return axios.get('/account/outbound_configurations')
}

export const fetchResourcesData = () => {
  return axios.get('/settings/outbound_configurations_resources')
}

export const createWebhook = (data) => {
  return axios.post('/settings/outbound_configurations', data)
}

export const updateWebhook = (id, data) => {
  return axios.put(`/settings/outbound_configurations/${id}`, data)
}

export const deleteWebhook = (id) => {
  return axios.delete(`/settings/outbound_configurations/${id}`)
}

export const fetchDetailWebhook = (event, resource) => {
  return axios.get(`/account/outbound_configuration_resources/preview?event=${event}&resource=${resource}&extra_attributes`)
}
