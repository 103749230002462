import * as api from '~api/settings/places'
import { createAsyncThunk } from '@reduxjs/toolkit'
import i18n from '~localization/index'
import deepMerge from 'deepmerge'

import { parseFetchResponse } from '~parsers'
import { parsePaginationAndMeta, parseResponseErrors } from 'frontend/parsers'
import { parsePlaceErrors, parsePlacesRequest } from 'frontend/parsers/places'
import { HTTP_ERRORS } from 'frontend/constants'

import { openSnackbar } from '~actions/layout'

const NAMESPACE = 'PLACES'

export const fetchPlaces = createAsyncThunk(
  `${NAMESPACE}/fetchPlaces`,
  async (args, { rejectWithValue }) => {
    try {
      const response = await api.fetchPlaces(args.params)
      const normalized = parseFetchResponse(response)
      return normalized
    } catch (error) {
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const deletePlace = createAsyncThunk(
  `${NAMESPACE}/deletePlace`,
  async ({ deleteItems, visiblePages, params = {} }, { dispatch, rejectWithValue }) => {
    const [id] = deleteItems.map(({ item }) => item.id)

    try {
      await api.deletePlace(id)
      const promises = visiblePages.map(page => api.fetchPlaces({ ...params, page }))
      const responses = await Promise.all(promises)
      const data = deepMerge.all(responses.map(response => parseFetchResponse(response)))
      data.pagination = parsePaginationAndMeta(responses[0].data).pagination

      dispatch(fetchPlaces.fulfilled(data))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:place.messages.delete-success')
      }))
    } catch (error) {
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:place.messages.delete-error')
      }))
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const getPlace = createAsyncThunk(
  `${NAMESPACE}/getPlace`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.getPlace(id)
      const normalized = parsePlacesRequest(response)
      return normalized
    } catch (error) {
      console.log(error)
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const updatePlace = createAsyncThunk(
  `${NAMESPACE}/updatePlace`,
  async ({ id, params }, { dispatch, rejectWithValue }) => {
    try {
      await api.updatePlace(id, params)
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:place.messages.update-success')
      }))
    } catch (error) {
      if (error?.response?.status === HTTP_ERRORS.UNPROCESSABLE_ENTITY) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:place.messages.fields-error')
        }))
        return rejectWithValue(parsePlaceErrors(error?.response?.data))
      } else {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:place.messages.update-error')
        }))
        return rejectWithValue(parseResponseErrors(error))
      }
    }
  }
)

export const createPlace = createAsyncThunk(
  `${NAMESPACE}/createPlace`,
  async ({ params }, { dispatch, rejectWithValue }) => {
    try {
      await api.createPlace(params)
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:place.messages.create-success')
      }))
    } catch (error) {
      if (error?.response?.status === HTTP_ERRORS.UNPROCESSABLE_ENTITY) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:place.messages.fields-error')
        }))
        return rejectWithValue(parsePlaceErrors(error?.response?.data))
      } else {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:place.messages.create-error')
        }))
        return rejectWithValue(parseResponseErrors(error))
      }
    }
  }
)
