import { hpLangs } from '@beetrack/hp-components'

import layoutsEs from './layouts.es.json'
import layoutsEn from './layouts.en.json'
import settingsEs from './settings.es.json'
import settingsEn from './settings.en.json'
import profileEn from './profile.en.json'
import profileEs from './profile.es.json'
import sharedEn from './shared.en.json'
import sharedEs from './shared.es.json'
import yupEn from './yup.en.json'
import yupEs from './yup.es.json'

export default {
  en: {
    honeypot: hpLangs.en,
    layouts: layoutsEn,
    settings: settingsEn,
    profile: profileEn,
    shared: sharedEn,
    yup: yupEn
  },
  es: {
    honeypot: hpLangs.es,
    layouts: layoutsEs,
    settings: settingsEs,
    profile: profileEs,
    shared: sharedEs,
    yup: yupEs
  }
}
