import React from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'formik'
import Checkbox from '@beetrack/hp-checkbox-react'

import { getDark } from '~selectors/layout'

export const FormikCheckbox = (props) => {
  const dark = useSelector(getDark)
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <Checkbox
          dark={dark}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          checked={field.value}
          disabled={form.isSubmitting}
          {...props}
        />
      )}
    </Field>
  )
}

export default FormikCheckbox
