import { store } from '~reducers/index'
import { ENTITY } from '~selectors/entities'
import { selectActivity } from '~selectors/settings/forms'
import { capitalize } from '~utils/strings'
import deepMerge from 'deepmerge'
import i18n from '~localization/index'

export const parseNormalizedPresentation = (presentationEntity, overrideAttributes = {}) => {
  const parsed = {
    entities: {
      [ENTITY.proofOfDeliveriesPresentation]: {
        [presentationEntity.id]: deepMerge(
          presentationEntity,
          overrideAttributes,
          { arrayMerge: (_destinationArray, sourceArray) => sourceArray }
        )
      }
    }
  }
  return parsed
}

export const parseNormalizedStatusesSubStatusesGroups = (entities = {}) => {
  const parsed = {
    entities: {
      [ENTITY.status]: {},
      [ENTITY.subStatus]: {},
      [ENTITY.group]: {},
      [ENTITY.groupCategory]: {}
    }
  }
  Object.values(entities?.[ENTITY.proofOfDeliveriesActivity] || {}).forEach(activity => {
    activity?.dispatch_statuses?.forEach(dispatchStatus => {
      parsed.entities[ENTITY.status][dispatchStatus.id] = dispatchStatus
    })
    activity?.dispatch_sub_statuses?.forEach(dispatchSubStatus => {
      parsed.entities[ENTITY.subStatus][dispatchSubStatus.id] = dispatchSubStatus
    })
  })

  Object.values(entities?.[ENTITY.proofOfDeliveriesPresentation] || {}).forEach(presentation => {
    presentation?.dispatch_statuses?.forEach(dispatchStatus => {
      parsed.entities[ENTITY.status][dispatchStatus.id] = dispatchStatus
    })
    presentation?.dispatch_sub_statuses?.forEach(dispatchSubStatus => {
      parsed.entities[ENTITY.subStatus][dispatchSubStatus.id] = dispatchSubStatus
    })
    presentation?.groups?.forEach((group) => {
      const { id, name, group_category_id: groupCategoryId, group_category_name: groupCategoryName } = group
      parsed.entities[ENTITY.group][id] = { id: id, name, group_category: { id: parseInt(groupCategoryId) } }
      if (groupCategoryId && groupCategoryName) {
        parsed.entities[ENTITY.groupCategory][groupCategoryId] = {
          id: parseInt(groupCategoryId),
          name: groupCategoryName
        }
      }
    })
  })
  return parsed
}

export const parsePresentationPayload = (formValues) => {
  return {
    [ENTITY.proofOfDeliveriesPresentation]: {
      name: formValues?.name || '',
      ...formValues
    }
  }
}

export const parseActivityPayload = (presentationId, formValues, duplicate = false) => {
  let formValuesCopy = { ...formValues }
  if (duplicate) {
    formValuesCopy = formValuesCopy.initialValues
    delete formValuesCopy.id
    formValuesCopy.choices_attributes.map((values) => delete values.id)
    // formValuesCopy.position++ // This line is commented until the backend has the service ready
  }
  const dataBaseActivity = selectActivity(presentationId, formValuesCopy.id)(store.getState())
  const formChoices = (formValuesCopy.cast === 'choice' && formValuesCopy?.choices_attributes) || []
  const deletedChoices = (dataBaseActivity?.initialValues?.choices_attributes || []).filter(
    dataBaseChoice => !formChoices.map(formChoice => formChoice.id).includes(dataBaseChoice.id)
  ).map(dataBaseChoice => ({
    ...dataBaseChoice,
    _destroy: true
  }))
  return {
    [ENTITY.proofOfDeliveriesActivity]: {
      ...formValuesCopy,
      name: duplicate ? `${formValuesCopy.name} - ${i18n.t('models:activity.copy')}` : formValuesCopy.name,
      // position: formValuesCopy.position, // This line is commented until the backend has the service ready
      choices_attributes: formValuesCopy.cast === 'choice' ? [
        ...formChoices.map(formChoice => ({
          ...!duplicate && formChoice.id && { id: formChoice.id },
          name: formChoice.name
        })),
        ...deletedChoices
      ] : undefined
    }
  }
}

export const parseProofOfDeliveriesError = (errorResponse = {}) => {
  let errors = {}
  Object.entries(errorResponse).forEach(([field, text]) => {
    errors = { ...errors, [field]: capitalize(text[0]) }
  })
  return errors
}

export const parsePresentationGroupOptions = (data = {}) => {
  const { entities = {}, result = {} } = data

  const { group_category: groupCategories, group: groups } = entities

  const { group: groupIds } = result

  if (!groupIds) return []

  var groupCategoriesIds = Object.keys(groupCategories).map(key => {
    return key
  })

  const options = groupCategoriesIds.map(id => {
    const groupCategory = groupCategories[id]

    return {
      label: groupCategory.name,
      id: groupCategory.id,
      options: groupIds.map(id => {
        const group = groups[id]
        const { group_category: currentGroupCategory } = group

        if (currentGroupCategory.id === groupCategory.id) return { value: group.id, label: group.name }
      }).filter(Boolean)
    }
  })
  return options
}
