import axios from '../config'

const nomaModel = 'dispatch_sub_statuses'

export const fetchSubStatuses = (params) => {
  return axios.get(`/account/${nomaModel}`, {
    params
  })
}

export const deleteSubStatus = (id) => axios.delete(`/settings/${nomaModel}/${id}.json`)
  .catch(error => { throw error })

export const createSubStatusesDefault = () => {
  return axios.post(`/account/${nomaModel}/create_default`).catch(error => { throw error })
}

export const fetchSubStatusesStatuses = () => axios.get('/account/dispatch_statuses', {})

export const fetchSubStatusesFormats = () => axios.get('/account/dispatch_sub_statuses/formats', {})

export const fetchSubStatusesModes = () => axios.get('/account/dispatch_sub_statuses/modes', {})

export const createSubStatus = (params) => {
  return axios.post(`/settings/${nomaModel}.json`, { dispatch_sub_status: params }).catch(error => { throw error })
}

export const getSubStatus = (id) => {
  return axios.get(`/account/${nomaModel}/${id}`).catch(error => { throw error })
}

export const updateSubStatus = (id, params) => {
  return axios.patch(`/settings/${nomaModel}/${id}.json`,
    params).catch(error => { throw error })
}
