import axios from './config'

export const currentUser = () => {
  return axios.get('/profile/current_user')
}

export const logout = () => {
  window.localStorage.clear()
  return axios.get('/users/sign_out')
    .finally(() => {
      window.location.reload(true)
    })
}
