import components from './es/components.json'
import general from './es/general.json'
import models from './es/models.json'
import pages from './es/pages.json'
import validations from './es/validations.json'

const resource = {
  components,
  general,
  models,
  pages,
  validations
}

export default resource
