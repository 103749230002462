import React from 'react'
import i18n from '~localization/index'

import {
  Widget,
  Activity,
  Route,
  ListLocation,
  Truck,
  Statistics,
  NotificationAlert,
  People,
  ArrowsInBold,
  FileCheck,
  Store,
  Upload,
  DollarFile
} from '@beetrack/hp-icons-react'

export const MOBILE_BREAKPOINT_FOR_SIDEBAR = 640

export const sidebarItems = [{
  key: 'holding',
  content: () => i18n.t('layouts:sidebar.titles.holding'),
  icon: <Widget />,
  link: '/resume/holding',
  mobileHidden: false,
  useRouter: false
}, {
  key: 'resume',
  content: () => i18n.t('layouts:sidebar.titles.resume'),
  icon: <Activity />,
  link: '/activities',
  mobileHidden: false,
  useRouter: false
}, {
  key: 'route',
  content: () => i18n.t('layouts:sidebar.titles.route'),
  icon: <Route />,
  link: '/routes',
  mobileHidden: false,
  useRouter: false
}, {
  key: 'search',
  content: () => i18n.t('layouts:sidebar.titles.search'),
  icon: <ListLocation />,
  link: '/search',
  mobileHidden: false,
  useRouter: false
}, {
  key: 'fleet',
  content: () => i18n.t('layouts:sidebar.titles.fleet'),
  icon: <Truck />,
  link: '/fleet',
  mobileHidden: true,
  useRouter: false
}, {
  key: 'reporting',
  content: () => i18n.t('layouts:sidebar.titles.reporting'),
  icon: <Statistics />,
  link: '/reporting',
  mobileHidden: false,
  useRouter: false
}, {
  key: 'notification',
  content: () => i18n.t('layouts:sidebar.titles.notification'),
  icon: <NotificationAlert />,
  link: '/notifications',
  mobileHidden: false,
  useRouter: false
}, {
  key: 'contacts',
  content: () => i18n.t('layouts:sidebar.titles.contacts'),
  icon: <People />,
  link: '/contacts',
  mobileHidden: false,
  useRouter: false
}, {
  key: 'payment',
  content: () => i18n.t('pages:payment.title'),
  icon: <DollarFile />,
  link: '/cost',
  mobileHidden: true,
  useRouter: true
}, {
  key: 'reverse_logistics',
  content: () => i18n.t('layouts:sidebar.titles.reverse_logistics'),
  icon: <ArrowsInBold />,
  link: '/reverse_logistics',
  mobileHidden: true,
  useRouter: false
}, {
  key: 'documents_delivery',
  content: () => i18n.t('layouts:sidebar.titles.documents_delivery'),
  icon: <FileCheck />,
  link: '/documents_delivery',
  mobileHidden: false,
  useRouter: false
}, {
  key: 'pickup',
  content: () => i18n.t('layouts:sidebar.titles.pickup'),
  icon: <Store />,
  link: '/pickup/dispatch_guides/receive_deliveries',
  mobileHidden: false,
  useRouter: false
}, {
  key: 'import',
  content: () => i18n.t('layouts:sidebar.titles.import'),
  icon: <Upload />,
  link: '/import/show',
  mobileHidden: true,
  useRouter: false
}]
