import axios from 'axios'
import qs from 'qs'
import { deleteObjectEmptyValues, isObjectEmpty } from '~utils/objects'

export const getCSRFToken = () => document.getElementsByTagName('meta')['csrf-token']?.content

const getSortParams = (sort = {}) => {
  if (isObjectEmpty(sort)) return

  const [name, direction] = sort ? Object.entries(sort)[0] : []

  return {
    sort_by: direction === 'desc' ? `-${name}` : name
  }
}

const getDateFilter = (date = {}) => {
  if (isObjectEmpty(date)) return {}

  return {
    start_date: date.from,
    end_date: date.to
  }
}

const formatParams = params => {
  const { page = 1, perPage, filter = {}, sort } = params
  const { date, ...otherFilters } = filter

  return deleteObjectEmptyValues({
    page,
    limit: perPage,
    ...otherFilters,
    ...getDateFilter(date),
    ...getSortParams(sort)
  })
}

export const buildRequest = params => {
  const parsedParams = []
  const stringifyConfig = { encode: false, arrayFormat: 'brackets' }
  parsedParams.push(
    qs.stringify(formatParams(params), stringifyConfig)
  )
  return parsedParams.join('&')
}

const axiosInstance = axios.create({
  paramsSerializer: buildRequest,
  headers: {
    'X-CSRF-Token': getCSRFToken()
  }
})

export default axiosInstance
