import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { useSelector } from 'react-redux'
import { getDark } from '~selectors/layout'

import { Switch } from '@beetrack/hp-components'

export const FormikSwitch = ({ name, disabled, ...otherProps }) => {
  const dark = useSelector(getDark)

  return (
    <Field name={name}>
      {({ field, form }) => {
        const fieldDisabled = disabled || form.isSubmitting

        return (
          <Switch
            dark={dark}
            name={name}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            checked={field.value}
            disabled={fieldDisabled}
            {...otherProps}
          />
        )
      }}
    </Field>
  )
}

FormikSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

FormikSwitch.defaultProps = {
  disabled: false
}

export default FormikSwitch
