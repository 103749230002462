import React, { useEffect, useState } from 'react'

import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Page, Button } from '@beetrack/hp-components'
import { Face, Edit } from '@beetrack/hp-icons-react'

import { withSettingsSidebar } from '~components/lastmile_settings_sidebar'
import { PageExplanation } from '~components/page_explanation'
import { PageTable, PageFilter } from '~components/shared'
import AddressInput from '~components/address_input'
import { DatePickerFilter, TextFieldFilter, SelectFilter } from '~components/shared/filters'

import {
  handleFetchReducer, handlePageFulfilled, fetchInitialState, pagedInitialState
} from '~reducers/shared'
import { parseFetchResponse } from '~parsers'
import { useFetchWithURL, useURLParams } from '~hooks'
import { getDark } from '~selectors/layout'
import { selectAsyncStatus } from '~selectors/shared'
import { isObjectEmpty } from '~utils/objects'

const fakeData = {
  'data': {
    'data': [{
      'id': '1',
      'type': 'playground',
      'attributes': {
        'name': 'Playground 1',
        'date': '2022-01-01',
        'user': 'Diego Plaza'
      }
    }, {
      'id': '2',
      'type': 'playground',
      'attributes': {
        'name': 'Playground 2',
        'date': '2022-01-01',
        'user': 'Yennifer Pernia'
      }
    },
    {
      'id': '3',
      'type': 'playground',
      'attributes': {
        'name': 'Playground 3',
        'date': '2022-01-01',
        'user': 'Daniel Rivera'
      }
    }],
    'meta': {
      'total_elements': 3,
      'total_pages': 1,
      'current_page': 1,
      'objects_per_page': 30,
      'other_custom_meta': 99
    }
  }
}

// api/playground.js

const api = {
  fetchPlaygrounds: () => new Promise(resolve => {
    setTimeout(() => resolve(fakeData), 2000)
  })
}

// end api

// redux/actions/playground.js

export const fetchPlaygrounds = createAsyncThunk(
  'playground/fetchPlaygrounds',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      console.log('fetchPlaygrounds params: ', params)
      const response = await api.fetchPlaygrounds(params)
      const normalized = parseFetchResponse(response)
      // para gatillar un error, des-comenta la siguiente linea:
      // console.log(a)
      return normalized
    } catch (error) {
      console.error(error)

      // como aún no hay un estándares de error, tal vez tendremos que tener varios "parsers"
      const parsedError = {
        code: 422,
        text: 'There was an error',
        details: 'Some details about the error'
      }
      return rejectWithValue(parsedError)
    }
  }
)

// end actions

// redux/reducers/playground.js

const initialState = {
  ...fetchInitialState,
  ...pagedInitialState,
  'some-other-sub-state': {
    ...fetchInitialState,
    ...pagedInitialState
  }
}

export const playgroundReducer = createReducer(initialState, builder =>
  builder
    // you can always do some specific logic here
    .addCase(fetchPlaygrounds.fulfilled, (state, action) => {
      state.someSpecificData = 'im-flexible'
    })
    // use handleFetchReducer for handling the loadings, this can be used for tables or
    // anything else
    .addMatcher(...handleFetchReducer(fetchPlaygrounds))
    // by passing a second parameter, all data will be store in state.some-other-sub-state
    .addMatcher(...handleFetchReducer(fetchPlaygrounds, 'some-other-sub-state'))
    // use handlePageFulfilled for pagination related fetch: table, select, etc
    .addMatcher(...handlePageFulfilled(fetchPlaygrounds))
    // use handlePageFulfilled with a second parameter to set the data inside state.some-other-sub-state
    // this will be useful for pages where there are multiple fetch data to store
    .addMatcher(...handlePageFulfilled(fetchPlaygrounds, 'some-other-sub-state'))
)
// end reducer

// redux/selectors/playground.js

const selectPlaygroundIds = state => state.playground?.ids || {}

const selectPlaygroundPagination = state => state.playground.pagination

const selectPlayground = state => id => state.entities?.playground?.[id]

// end selectors

export const PlaygroundPage = () => {
  const dark = useSelector(getDark)
  const [{ filter }] = useURLParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ids = useSelector(selectPlaygroundIds)
  const getPlayground = useSelector(selectPlayground)
  const pagination = useSelector(selectPlaygroundPagination)
  const loading = useSelector(selectAsyncStatus('playground'))
  const [addressName, setAddressName] = useState('')
  const [address, setAddress] = useState({})

  const fetchData = params => {
    return dispatch(fetchPlaygrounds(params))
  }

  const defaultParams = { filter: { name: 'Diego' } }
  const { initialFetch, updateData } = useFetchWithURL(fetchData, defaultParams)
  useEffect(() => { initialFetch() }, [])

  const columns = [{
    title: 'Name',
    name: 'name',
    valueByAttribute: 'name'
  }, {
    title: 'User',
    name: 'user',
    valueByAttribute: 'user'
  }, {
    title: 'Date',
    name: 'date',
    valueByAttribute: 'date',
    sortable: true
  }]

  const actions = [{
    title: 'Action 1',
    icon: <Face />,
    onClick: () => { },
    isGlobal: true
  }, {
    title: 'Action 2',
    icon: <Edit />,
    onClick: () => { },
    isGlobal: true
  }]

  const handleChange = (e) => {
    setAddressName(e.target.value)
  }

  const handleSelect = (address) => {
    setAddressName(address.name)
    setAddress(address)
  }

  return (
    <Page
      dark={dark}
      title={t('pages:example.title')}
      bodyAsCard
      buttons={[
        <Button dark={dark} type='tertiary' key='button-3' icon={<Face />}>{t('pages:example.filter')}</Button>,
        <Button dark={dark} type='secondary' key='button-2' icon={<Face />}>{t('pages:example.button')}</Button>,
        <Button dark={dark} key='button-1' icon={<Face />}>{t('pages:example.button')}</Button>
      ]}
    >
      <PageExplanation
        title={t('pages:example.subtitle')}
        description={t('pages:example.description')}
      />
      <AddressInput
        label={t('pages:example.address')}
        additionalClassName='grid__item-12'
        onChange={handleChange}
        onSelect={handleSelect}
        value={addressName}
      />
      {!isObjectEmpty(address) &&
        <p >Latitud: {address.lat} Longitud: {address.lng} Nombre: {address.name}</p>}
      <br />
      <PageFilter
        updateData={updateData}
        value={filter}
      >
        <DatePickerFilter name='date' label='Fecha' />
        <TextFieldFilter name='name' label='Nombre' />
        <SelectFilter
          name='playground'
          label='Playground'
          options={[
            { label: 'Playground 1', value: 'p1' },
            { label: 'Playground 2', value: 'p2' },
            { label: 'Playground 3', value: 'p3' }
          ]}
        />
      </PageFilter>
      <PageTable
        actions={actions}
        columns={columns}
        ids={ids}
        updateData={updateData}
        pagination={pagination}
        bottomExtraHeight={30}
        loading={loading}
        getItemFromData={getPlayground}
      />
    </Page>
  )
}

export default withSettingsSidebar(PlaygroundPage)
