import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import normalizeJsonAPI from '@beetrack/jsonapi-normalizer'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { setDispatchGuide, setOrderSelected, setWidgetConfig, setWidgetKey } from '~actions/widget_result'
import { storeEntities } from '~actions/entities'

import WidgetResult from '~pages/public/widget_result'

export const WidgetApp = ({ widgetData }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (widgetData) {
      const normalized = normalizeJsonAPI(widgetData, true)
      dispatch(setWidgetConfig(widgetData.meta.widget_config))
      dispatch(setWidgetKey(widgetData.meta.widget_key))
      dispatch(storeEntities(normalized))
      if (!normalized.result?.dispatch_guide) return
      dispatch(setDispatchGuide(normalized.result['dispatch_guide']))
      dispatch(setOrderSelected(normalized.result['dispatch_guide'][0]))
    }
  }, [widgetData])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/widget/:key' element={<WidgetResult />} />
        <Route path='/widget/notification_result/:code' element={<WidgetResult />} />
        <Route path='/track/:beecode' exact element={<WidgetResult />} />
      </Routes>
    </BrowserRouter>
  )
}

export default WidgetApp
