import normalizeJsonAPI from 'jsonapi-normalizer'

export const VEHICLE_TYPES = {
  truck: {
    value: 0,
    order: 0
  },
  pickup: {
    value: 1,
    order: 1
  },
  car: {
    value: 2,
    order: 2
  },
  motorcycle: {
    value: 3,
    order: 3
  },
  bicycle: {
    value: 4,
    order: 4
  },
  other: {
    value: 5,
    order: 11
  },
  electric: {
    value: 6,
    order: 5
  },
  electric_motorcycle: {
    value: 7,
    order: 6
  },
  electric_bicycle: {
    value: 8,
    order: 7
  },
  electric_cart: {
    value: 9,
    order: 8
  },
  electric_car: {
    value: 10,
    order: 9
  },
  motorbike: {
    value: 11,
    order: 10
  }
}

export const getGroupCategoriesOptions = (response) => {
  const { result, entities } = normalizeJsonAPI(response.data, true)

  const { group_category: groupCategories = [] } = result

  return groupCategories.map(categoryId => {
    const groupCategory = entities.group_category[categoryId]

    return {
      label: groupCategory.name,
      // the category has 0 or many groups, so an empty array is possible
      options: groupCategory.groups.map(groupId => {
        const group = entities.group[groupId]
        return {
          label: group.name,
          value: group.id
        }
      })
    }
  })
}

export const parseTruckTypes = (t) => Object.keys(VEHICLE_TYPES)
  .sort((a, b) => VEHICLE_TYPES[a].order - VEHICLE_TYPES[b].order)
  .map(type => ({
    label: t(`models:trucks.vehicle_type.${type}`),
    value: type
  }))
