import React from 'react'
import PropTypes from 'prop-types'
import { hpUtils, FatSpinner } from '@beetrack/hp-components'
import { Check, Close } from '@beetrack/hp-icons-react'

export const Step = (props) => {
  const { title, description, date, type = 'default' } = props

  return (
    <div className='step'>
      <div className={hpUtils.getBEMClasses('step__icon', { [type]: type })}>
        {type === 'success' && <Check />}
        {type === 'warning' && <Check />}
        {type === 'error' && <Close />}
        {type === 'loading' && <FatSpinner /> }
        {type === 'default' && <div className='default'> </div> }
      </div>
      <div className='step__content'>
        <div className='step__content-title'>
          <span className='step__title'>{title}</span>
          { description && <div className='step__description'>{ description }</div> }
        </div>
        {date &&
          <div className='step__content-date'>
            {date}
          </div>
        }
      </div>
    </div>
  )
}

Step.defaultProps = {
  type: 'default'
}

Step.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  date: PropTypes.string,
  type: PropTypes.oneOf(['error', 'success', 'warning', 'loading', 'default']).isRequired
}

export default Step
