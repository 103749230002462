import { createSelectorCreator, defaultMemoize } from 'reselect'

import { isObject, getSubObject } from '~utils'
import { EVENT_TYPES } from '~constants'

// This is a custom selector. All reselect selectors created with "createSelector" have an equality check
// function. By default this function is an shallow comparison (a === b), this works for almost anything except
// objects and arrays. When comparing an abject or array with a === b, it will only compare the reference.
// As we are using Redux, with any change on any reducer will return a NEW object or array wich will have a
// different reference that will cause that a === b is always true.
// TO handle this, my proposal, for *SOME* selectors, is that reselect checks if the objects or array are different
// only by checking the length. That means that all the selectors created with createLengthEqualSelector will
// only recalculate if the nre object has more o less keys or if the array has more o less positions
// If its not object or array, it will do a shallow comparison.
export const createLengthEqualSelector = createSelectorCreator(
  defaultMemoize,
  (a, b) => {
    if (Array.isArray(a) && Array.isArray(b)) return a.length === b.length

    if (isObject(a) && isObject(b)) return Object.keys(a).length === Object.keys(b).length

    return a === b
  }
)

export const selectAsyncStatus = (slice, stateKey, subKey) => fullState => {
  const state = getSubObject(fullState[slice], stateKey, subKey)

  const { loading, error } = state

  const isLoading = event => loading === event && !error

  return {
    hasError: !loading && !!error,
    hasLoaded: !loading && !error,
    isLoading: !!loading && !error,
    // general
    isFirstTime: isLoading(EVENT_TYPES.DEFAULT),
    isPaginating: isLoading(EVENT_TYPES.PAGING),
    isSorting: isLoading(EVENT_TYPES.SORTING),
    isFiltering: isLoading(EVENT_TYPES.FILTERING),
    isSaving: isLoading(EVENT_TYPES.SAVING),
    isDeleting: isLoading(EVENT_TYPES.DELETING)
  }
}
