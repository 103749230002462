import React from 'react'
import { useSelector } from 'react-redux'
import { SettingsSidebar, SettingsGroup, SettingsLink } from '@beetrack/hp-components'

import { getSettingsSidebarData } from '~selectors/layout'
import { SettingsSidebarLoading } from './settings_sidebar_loading'

export const LastmileSettingsSidebar = () => {
  const { dark, groups, isLoading } = useSelector(getSettingsSidebarData)

  return (
    <SettingsSidebar dark={dark}>
      {isLoading ? (
        <SettingsSidebarLoading />
      ) : (
        groups.map(group => (
          <SettingsGroup title={group.title} key={group.title}>
            {group.links.map((groupLink, index) => (
              <SettingsLink
                key={index}
                title={groupLink.title}
                to={groupLink.url}
                useRouter={groupLink.useRouter}
              />
            ))}
          </SettingsGroup>
        )))}
    </SettingsSidebar>
  )
}

export default LastmileSettingsSidebar
