
function memoize (func) {
  let cache
  window.addEventListener('DOMContentLoaded', function () {
    cache = func()
  })
  return function () {
    if (!cache) {
      cache = func()
    }
    return cache
  }
}

const constantsFromBody = {
  'MAP': {
    'here_maps': 'leaflet',
    'osm': 'leaflet',
    'google_maps': 'google'
  },
  'TILES': {
    'here_maps': 'here',
    'osm': 'osm'
  },
  'GEOCODING': {
    'here': 'here',
    'google_premier': 'google',
    'nominatim': 'here'
  },
  'ROUTING': {
    'osrm': 'osrm',
    'google': 'google'
  }
}

const getParsedConstantFromDataset = (name, valuesName) => {
  if (document.body && document.body.dataset && document.body.dataset.map) {
    let mapData = JSON.parse(document.body.dataset.map)
    return constantsFromBody[valuesName][mapData[name]]
  }
}

export const CreateConstantsFromBody = function () {
  let constants = {}

  Object.defineProperty(constants, 'MAP', {
    value: memoize(() => getParsedConstantFromDataset('tiles', 'MAP'))
  })

  Object.defineProperty(constants, 'TILES', {
    value: memoize(() => getParsedConstantFromDataset('tiles', 'TILES'))
  })

  Object.defineProperty(constants, 'GEOCODING', {
    value: memoize(() => getParsedConstantFromDataset('geocoding', 'GEOCODING'))
  })

  return constants
}

export const HERE_API_KEY = process.env.HERE_API_KEY

export const HERE_TILES_CONFIG = {
  tile: 'mapnopttile',
  version: 'newest',
  style: 'default',
  scheme: 'reduced.day',
  size: '256',
  format: 'png8'
}

export const getMapTileConfig = () => {
  const tile = window.Constants.TILES()
  const OSM_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  const OSM_ATTRIBUTION = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'

  if (tile === 'here') {
    return {
      url: 'https://2.base.maps.ls.hereapi.com/maptile/2.1/{tile}/{version}/{scheme}/{z}/{x}/{y}/{size}/{format}?style={style}&apiKey={apiKey}',
      options: {
        attribution: `Map Tiles &copy; ${new Date().getFullYear()} <a href="http://developer.here.com">HERE</a>`,
        apiKey: HERE_API_KEY,
        ...HERE_TILES_CONFIG
      },
      fallback: {
        url: OSM_URL,
        attribution: OSM_ATTRIBUTION,
        statusUrl: `https://2.base.maps.ls.hereapi.com/maptile/2.1/${HERE_TILES_CONFIG.tile}/${HERE_TILES_CONFIG.version}/${HERE_TILES_CONFIG.scheme}/0/0/0/${HERE_TILES_CONFIG.size}/${HERE_TILES_CONFIG.format}?style=${HERE_TILES_CONFIG.style}&apiKey=${HERE_API_KEY}`
      }
    }
  }

  return {
    url: OSM_URL,
    options: {
      attribution: OSM_ATTRIBUTION
    }
  }
}

export const MAP_ZOOM = 14
export const MAP_MAX_ZOOM = 17
export const MAP_MAX_FITBOUNDS_ZOOM = 15

export const setWindowConstants = () => {
  Object.defineProperty(window, 'Constants', { value: CreateConstantsFromBody() })
  Object.defineProperty(window, 'MAP_TILE_CONF', { value: getMapTileConfig() })
}
