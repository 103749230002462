import React from 'react'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'

import { selectHistories } from '~selectors/widget_result'

import { Step, Stepper } from '~components/stepper'

import { DATE_TIME_FORMAT_API, DATE_TIME_FORMAT, STEP_COLOR_WIDGET_RESULT } from 'frontend/constants'

const WidgetResultTracking = (props) => {
  const { idHistories } = props
  const histories = useSelector(selectHistories(idHistories))

  return (
    <div className='widget-result__tracking'>
      <label className='widget-result__tracking--label'>{histories[0]?.code_name}</label>
      <Stepper additionalClassName='widget-result__stepper'>
        {histories.map((history, index) =>
          <Step key={index}
            title={history.code_name}
            date={DateTime.fromFormat(history.happened_at, DATE_TIME_FORMAT_API).toFormat(DATE_TIME_FORMAT)}
            description={history.dispatchSubStatus?.name}
            type={STEP_COLOR_WIDGET_RESULT[history.status_id]} />
        )}
      </Stepper>
    </div>
  )
}

export default WidgetResultTracking
