import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { selectAddress, selectContact, selectWidgetConfig } from '~selectors/widget_result'

import { WidgetResultInformationData } from './widget_result_information-data'
import { WidgetResultItems } from './widget_result_items'
import { WidgetResultComments } from './widget_result_comments'

const WidgetResultInformation = (props) => {
  const { t } = useTranslation()
  const { order } = props
  const { contact: contactId, address: addressId, items: itemsId = [], truck_driver: driver, dispatch,
    max_delivery_time: maxDeliveryTime, number_of_pending_delivery_points: numberOfPendingDeliveryPoints,
    show_pending_delivery_points: showPendingDeliveryPoints, comments: commentsId = []
  } = order

  const contact = useSelector(selectContact(contactId))
  const address = useSelector(selectAddress(addressId))
  const { permissions } = useSelector(selectWidgetConfig)

  return (
    <div className='widget-result-information'>
      <WidgetResultInformationData
        contact={contact}
        address={address}
        maxDeliveryTime={maxDeliveryTime}
        numberOfPendingDeliveryPoints={numberOfPendingDeliveryPoints}
        estimatedAt={dispatch?.estimated_at}
        arrivedAt={dispatch?.arrived_at}
        isManaged={order.isManaged}
        showPendingDeliveryPoints={showPendingDeliveryPoints}
      />
      {driver && permissions.truck_driver_photo && <div className='widget-result-driver'>
        <img src={driver?.approved_picture} />
        <div className='widget-result-driver__content'>
          <label className='widget-result-driver__content--title'>
            {t('components:widget-result.information.driver')}
          </label>
          <label className='widget-result-driver__content--name'>{driver.name} </label>
        </div>
      </div>}
      {itemsId.length > 0 && (
        <WidgetResultItems itemsId={itemsId} dispatch={dispatch} showDelivered={order.isManaged} />
      )}
      {commentsId.length > 0 && (
        <WidgetResultComments commentsId={commentsId} />
      )}
    </div>
  )
}

export default WidgetResultInformation
