import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import normalizeJsonAPI from '@beetrack/jsonapi-normalizer'

import { storeEntities, replaceEntities } from '../entities'
import * as api from '~api/settings/webhooks'
import { ENTITY } from '~selectors/entities'
import { parseWebhookRequest, parseWebhookErrors } from '~parsers'
import { openSnackbar } from '~actions/layout'
import i18n from '~localization/index'

const NAMESPACE = 'webhooks'

export const setAvailableResources = createAction(`${NAMESPACE}/setAvailableResources`)

export const fetchWebhooks = createAsyncThunk(
  `${NAMESPACE}/fetchWebhooks`,
  async ({ afterUpdate } = {}, { dispatch }) => {
    try {
      const [response, resourcesResponse] = await Promise.all([
        api.fetchWebhooks(),
        !afterUpdate ? api.fetchResourcesData() : () => {}
      ])
      const normalized = normalizeJsonAPI(response.data, true)
      dispatch(storeEntities(normalized))

      if (!afterUpdate) {
        const normalizedResources = normalizeJsonAPI(resourcesResponse.data, true)
        dispatch(storeEntities(normalizedResources))
        dispatch(setAvailableResources(normalizedResources.result[ENTITY.webhookResource]))
      }
      return normalized.result[ENTITY.webhook]
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
)

export const createWebhook = createAsyncThunk(
  `${NAMESPACE}/createWebhook`,
  async ({ ...params }, { dispatch, rejectWithValue }) => {
    try {
      await api.createWebhook(parseWebhookRequest(params))
      await dispatch(fetchWebhooks({ afterUpdate: true }))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:webhooks.create-success')
      }))
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:webhooks.fields-error')
        }))
        return rejectWithValue(parseWebhookErrors(error?.response?.data))
      } else {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:webhooks.generic-error')
        }))
        return rejectWithValue({})
      }
    }
  }
)

export const updateWebhook = createAsyncThunk(
  `${NAMESPACE}/updateWebhook`,
  async ({ id, ...params } = {}, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.updateWebhook(id, parseWebhookRequest(params))
      const normalized = normalizeJsonAPI(response.data, true)
      dispatch(replaceEntities(normalized))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:webhooks.update-success')
      }))
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:webhooks.fields-error')
        }))
        return rejectWithValue(parseWebhookErrors(error?.response?.data))
      } else {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:webhooks.generic-error')
        }))
        return rejectWithValue({})
      }
    }
  }
)

export const deleteWebhook = createAsyncThunk(
  `${NAMESPACE}/deleteWebhook`,
  async (id, { dispatch }) => {
    try {
      await api.deleteWebhook(id)
      await dispatch(fetchWebhooks({ afterUpdate: true }))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:webhooks.delete-success')
      }))
    } catch (error) {
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:webhooks.generic-error')
      }))
      console.error(error)
      return Promise.reject(error)
    }
  }
)

export const fetchDetailWebhook = createAsyncThunk(
  `${NAMESPACE}/fetchDetailWebhook`,
  async ({ event, resource }) => {
    try {
      const response = await api.fetchDetailWebhook(event, resource)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }
)
