import { capitalize } from '~utils/utils'

export const parseWidgetRequest = ({ data }) => {
  const widgets = data.data
  return widgets.map(widget => {
    const { attributes } = widget
    const { name } = attributes
    return { label: name, value: widget.id }
  })
}

export const parseCategoryGroupsRequest = (data) => {
  const categories = data

  return categories.map(category => {
    return { label: category.label, options: category.options }
  })
}

export const parseLogoPicture = (dataURL) => {
  const base64File = dataURL.split(',')[1]

  return base64File
}

export const parseWidgetErrors = (errorResponse = {}) => {
  const { resource } = errorResponse
  let errors = {}

  Object.entries(resource).forEach(([field, text]) => {
    errors = { ...errors, [field]: capitalize(text[0], false) }
  })
  return errors
}
