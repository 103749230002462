import i18next from '~localization'

import * as Yup from 'yup'

export const mergeValidations = (...schemas) => {
  const [first, ...rest] = schemas
  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first)
  return merged
}

/* Main Formik Field Validations */

export const isRequired = () => Yup.mixed().required(i18next.t('validations:required')).nullable()

export const isArrayNotEmpty = () => Yup.array().min(1, i18next.t('validations:not-empty'))

export const listOfObject = validations => Yup.array().of(Yup.object().shape(validations))

export const isFloat = () => Yup.string().matches(/^[-+]?[0-9]+\.?[0-9]+$/, i18next.t('validations:is-float'))

export const isInRange = ({ min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY }) => {
  return Yup.number()
    .typeError(i18next.t('validations:is-number'))
    .min(min, i18next.t('validations:min', { value: min }))
    .max(max, i18next.t('validations:max', { value: max }))
    .nullable()
}

export const isEmail = () =>
  Yup.string().matches(
    /^[A-Z0-9$&+,:;=?¿@#|'<>._^*%!¡°´"/¨{}·[\]-]+@[A-Z0-9$&+,:;=?¿@#|'<>._^*%!¡°´"/¨{}·[\]-]+\.[A-Z]{2,4}$/i,
    i18next.t('validations:is-email')
  )

export const isValidPhoneNumber = () => Yup.string()
  .matches(/^[+]?(?=)(?:\d+,)*\d*(?:\d+)?$/, i18next.t('validations:is-float'))

export const isValidColor = () => Yup.string()
  .matches(/^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})([a-fA-F0-9]{2})?$/,
    i18next.t('validations:is-valid-color')
  )
