import React from 'react'
import { Formik } from 'formik'
import { FormikSelect, FormikTextField } from '~components/formik_fields'
import { Button } from '@beetrack/hp-components'
import { useSelector } from 'react-redux'
import { selectNotificationChannels } from '~selectors/widget_result'
import { validationSchema } from './validationSchema'
import { useTranslation } from 'react-i18next'

export const WidgetResultSubscriberCreator = ({ onSubmit }) => {
  const { t } = useTranslation()
  const notificationChannels = useSelector(selectNotificationChannels)

  return (
    <Formik
      initialValues={{ 'channel_type': notificationChannels?.[0].value }}
      validationSchema={validationSchema()}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setErrors, resetForm }) => {
        onSubmit(values).catch((error) => setErrors(error))
        resetForm({
          values: {
            ...values,
            name: ''
          }
        })
      }}
    >
      {({ errors, handleSubmit, isSubmitting, dirty }) => (
        <div>
          <div className='widget-result-subscriber-creator'>
            <FormikSelect
              dark={false}
              additionalClassName='widget-result-subscriber-creator__select'
              showHelperSpace={false}
              name='channel_type'
              options={notificationChannels}
              required
              noBorder
            />
            <FormikTextField
              dark={false}
              additionalClassName='widget-result-subscriber-creator__text'
              showHelperSpace={false}
              name='name'
              required
              noBorder
              maxLength='260'
            />
            <Button
              additionalClassName='widget-result-subscriber-creator__button'
              key='save-button'
              type='primary'
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={!dirty || isSubmitting}
            >
              {t('components:widget-result.notification.button')}
            </Button>
          </div>
          {!errors.name && <p className='widget-result-subscriber-creator__placeholder'>
            {t('components:widget-result.notification.placeholder')}</p>}
          <p className='error'>{errors.name}</p>
        </div>
      )}
    </Formik>
  )
}

export default WidgetResultSubscriberCreator
