import React, { useState } from 'react'

import { ChevronDown, ChevronUp } from '@beetrack/hp-icons-react'
import { IconButton, hpUtils } from '@beetrack/hp-components'

export const Collapsible = (props) => {
  const { title, children, withBorder } = props
  const [showChildren, setShowChildren] = useState(false)

  return (
    <div className={hpUtils.getBEMClasses('collapsible', { 'with-border': withBorder })}>
      <div className='collapsible__title'>
        <label>{title}</label>
        <IconButton
          icon={showChildren ? <ChevronDown /> : <ChevronUp />}
          size='small'
          onClick={() => setShowChildren(!showChildren)}
        />
      </div>
      {showChildren && <div className='collapsible__children'>{children}</div>}
    </div>
  )
}

export default Collapsible
