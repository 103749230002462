import * as api from '~api/settings/widget'

import normalizeJsonAPI from '@beetrack/jsonapi-normalizer'
import { createAsyncThunk } from '@reduxjs/toolkit'
import i18n from '~localization/index'
import deepMerge from 'deepmerge'

import { parseFetchResponse } from '~parsers'
import { parsePaginationAndMeta, parseResponseErrors } from 'frontend/parsers'

import { parseWidgetRequest, parseCategoryGroupsRequest, parseWidgetErrors } from 'frontend/parsers/settings/widget'

import { openSnackbar } from '~actions/layout'
import { HTTP_ERRORS } from 'frontend/constants'

const ENTITY_NAME = 'widget'

export const fetchWidgets = createAsyncThunk(
  `${ENTITY_NAME}/fetchWidgets`,
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.fetchWidgets(args?.params)

      const normalized = parseFetchResponse(response)

      return normalized
    } catch (error) {
      console.error(error)
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const createWidget = createAsyncThunk(
  `${ENTITY_NAME}/createWidget`,
  async ({ params }, { dispatch, rejectWithValue }) => {
    try {
      await api.createWidget(params)
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:widget.messages.create-success')
      }))
    } catch (error) {
      if (error?.response?.status === HTTP_ERRORS.UNPROCESSABLE_ENTITY) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:widget.messages.create-error')
        }))
        return rejectWithValue(parseWidgetErrors(error?.response?.data))
      } else {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:widget.messages.create-error')
        }))
        return rejectWithValue(parseResponseErrors(error))
      }
    }
  }
)

export const deleteWidget = createAsyncThunk(
  `${ENTITY_NAME}/deleteWidget`,
  async ({ deleteItems, visiblePages, params = {} }, { dispatch, rejectWithValue }) => {
    const [id] = deleteItems.map(({ item }) => item.id)

    try {
      await api.deleteWidget(id)
      const promises = visiblePages.map(page => api.fetchWidgets({ ...params, page }))
      const responses = await Promise.all(promises)
      const data = deepMerge.all(responses.map(response => parseFetchResponse(response)))
      data.pagination = parsePaginationAndMeta(responses[0].data).pagination

      dispatch(fetchWidgets.fulfilled(data))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:widget.messages.delete-success')
      }))
    } catch (error) {
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:widget.messages.delete-error')
      }))
      return rejectWithValue({})
    }
  }
)

export const updateWidget = createAsyncThunk(
  `${ENTITY_NAME}/updateWidget`,
  async ({ id, params }, { dispatch, rejectWithValue }) => {
    try {
      await api.updateWidget(id, params)
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:widget.messages.update-success')
      }))
    } catch (error) {
      if (error?.response?.status === HTTP_ERRORS.UNPROCESSABLE_ENTITY) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:widget.messages.create-error')
        }))
        return rejectWithValue(parseWidgetErrors(error?.response?.data))
      } else {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:widget.messages.update-error')
        }))
        return rejectWithValue(parseResponseErrors(error))
      }
    }
  }
)

export const getWidget = createAsyncThunk(
  `${ENTITY_NAME}/getWidget`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.getWidget(id)
      const normalized = normalizeJsonAPI(response.data)
      return normalized
    } catch (error) {
      const { response } = error
      return rejectWithValue(parseResponseErrors(response))
    }
  }
)

export const fetchWidgetConditions = createAsyncThunk(
  `${ENTITY_NAME}/fetchWidgetConditionsOptions`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchWidgetConditions()
      const normalized = parseWidgetRequest(response)
      return normalized
    } catch (error) {
      const { response } = error
      return rejectWithValue(parseResponseErrors(response))
    }
  }
)

export const fetchWidgetGroups = createAsyncThunk(
  `${ENTITY_NAME}/fetchWidgetGroupsOptions`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchWidgetGroups()
      const normalized = parseCategoryGroupsRequest(response)
      return normalized
    } catch (error) {
      const { response } = error
      return rejectWithValue(parseResponseErrors(response))
    }
  }
)

export const fetchWidgetTraffic = createAsyncThunk(
  `${ENTITY_NAME}/fetchWidgetTraffic`,
  async ({ params, id }, { rejectWithValue }) => {
    try {
      const response = await api.fetchWidgetTraffic({ params }, id)
      const normalized = parseFetchResponse(response)
      return normalized
    } catch (error) {
      console.error(error)
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)
