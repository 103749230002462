import { createReducer } from '@reduxjs/toolkit'
import {
  fetchProofOfDeliveries, fetchStatus, createPresentation, updatePresentation, deletePresentation,
  fetchPresentationDispatchSubStatus, fetchActivityTypes, fetchActivityDispatchSubStatus,
  resetActivityDispatchSubStatus, createActivity, updateActivity, deleteActivity, createDefaultSections,
  createDefaultActivity, updatePositionActivity, fetchPresentationGroups
} from '~actions/settings/forms'

import {
  createInitialState,
  handlePendingAsyncThunk,
  handleFulfilledAsyncThunk,
  handleRejectedAsyncThunk,
  derivedStatus
} from '~utils/async_thunk_factory'

const INITIAL_STATE = {
  fetchProofOfDeliveries: createInitialState(),
  fetchStatus: createInitialState(),
  fetchPresentationDispatchSubStatus: createInitialState(),
  fetchActivityTypes: createInitialState(),
  fetchActivityDispatchSubStatus: createInitialState(),
  fetchPresentationGroups: createInitialState(),
  isSaving: false,
  isDeleting: false,
  isCreateDefaultActivity: false,
  isMovingActivity: false
}

export const proofOfDeliveriesReducer = createReducer(INITIAL_STATE, {
  [createDefaultSections.pending]: (state, action) => {
    state.isSaving = true
  },
  [createDefaultSections.fulfilled]: (state, action) => {
    state.isSaving = false
    state.fetchProofOfDeliveries.data = action.payload
  },
  [createDefaultSections.rejected]: (state, action) => {
    state.isSaving = false
  },
  [fetchProofOfDeliveries.pending]: (state, action) => {
    state.fetchProofOfDeliveries = handlePendingAsyncThunk(state, action)
  },
  [fetchProofOfDeliveries.fulfilled]: (state, action) => {
    state.fetchProofOfDeliveries = handleFulfilledAsyncThunk(state, action)
  },
  [fetchProofOfDeliveries.rejected]: (state, action) => {
    state.fetchProofOfDeliveries = handleRejectedAsyncThunk(state, action)
  },
  [fetchStatus.pending]: (state, action) => {
    state.fetchStatus = handlePendingAsyncThunk(state, action)
  },
  [fetchStatus.fulfilled]: (state, action) => {
    state.fetchStatus = handleFulfilledAsyncThunk(state, action)
  },
  [fetchStatus.rejected]: (state, action) => {
    state.fetchStatus = handleRejectedAsyncThunk(state, action)
  },
  [createPresentation.pending]: (state) => {
    state.isSaving = true
  },
  [createPresentation.fulfilled]: (state, action) => {
    state.isSaving = false
    // append recently created presentation id
    state.fetchProofOfDeliveries.data = [
      ...state.fetchProofOfDeliveries.data,
      ...action.payload
    ]
  },
  [createPresentation.rejected]: (state) => {
    state.isSaving = false
  },
  [updatePresentation.pending]: (state) => {
    state.isSaving = true
  },
  [updatePresentation.fulfilled]: (state) => {
    state.isSaving = false
  },
  [updatePresentation.rejected]: (state) => {
    state.isSaving = false
  },
  [deletePresentation.pending]: (state) => {
    state.isDeleting = true
  },
  [deletePresentation.fulfilled]: (state, action) => {
    const { meta: { arg: { id: deletedPresentationId } } } = action
    state.fetchProofOfDeliveries.data = state.fetchProofOfDeliveries.data
      .filter(presentatioId => presentatioId !== deletedPresentationId)
    state.isDeleting = false
  },
  [deletePresentation.rejected]: (state) => {
    state.isDeleting = false
  },
  [createActivity.pending]: (state, action) => {
    state.isSaving = true
  },
  [createActivity.fulfilled]: (state, action) => {
    state.isSaving = false
  },
  [createActivity.rejected]: (state, action) => {
    state.isSaving = false
  },
  [updateActivity.pending]: (state, action) => {
    state.isSaving = true
  },
  [updateActivity.fulfilled]: (state, action) => {
    state.isSaving = false
  },
  [updateActivity.rejected]: (state, action) => {
    state.isSaving = false
  },
  [deleteActivity.pending]: (state, action) => {
    state.isDeleting = true
  },
  [deleteActivity.fulfilled]: (state, action) => {
    state.isDeleting = false
  },
  [deleteActivity.rejected]: (state, action) => {
    state.isDeleting = false
  },
  [fetchActivityTypes.pending]: (state, action) => {
    state.fetchActivityTypes = handlePendingAsyncThunk(state, action)
  },
  [fetchActivityTypes.fulfilled]: (state, action) => {
    state.fetchActivityTypes = handleFulfilledAsyncThunk(state, action)
  },
  [fetchActivityTypes.rejected]: (state, action) => {
    state.fetchActivityTypes = handleRejectedAsyncThunk(state, action)
  },
  [fetchActivityDispatchSubStatus.pending]: (state, action) => {
    const { meta: { arg: { page } } } = action
    if (page === 1) {
      state.fetchActivityDispatchSubStatus = handlePendingAsyncThunk(state, action)
    }
  },
  [fetchActivityDispatchSubStatus.fulfilled]: (state, action) => {
    const { ids, meta } = action.payload
    const { ids: currentIds } = state.fetchActivityDispatchSubStatus.data || {}

    const data = {
      ids: Array.isArray(currentIds) ? [...currentIds, ...ids] : ids,
      meta
    }

    state.fetchActivityDispatchSubStatus = ({
      data,
      status: derivedStatus('SUCCESS'),
      error: undefined
    })
  },
  [fetchActivityDispatchSubStatus.rejected]: (state, action) => {
    state.fetchActivityDispatchSubStatus = handleRejectedAsyncThunk(state, action)
  },
  [resetActivityDispatchSubStatus]: (state) => {
    state.fetchDispatchSubStatus = INITIAL_STATE.fetchDispatchSubStatus
  },
  [fetchPresentationDispatchSubStatus.pending]: (state, action) => {
    const { meta: { arg: { page } } } = action
    if (page === 1) {
      state.fetchPresentationDispatchSubStatus = handlePendingAsyncThunk(state, action)
    }
  },
  [fetchPresentationDispatchSubStatus.fulfilled]: (state, action) => {
    const { ids, meta } = action.payload
    const { ids: currentIds } = state.fetchPresentationDispatchSubStatus.data || {}
    const data = {
      ids: (Array.isArray(currentIds) ? [...currentIds, ...ids] : ids),
      meta
    }

    state.fetchPresentationDispatchSubStatus = ({
      data,
      status: derivedStatus('SUCCESS'),
      error: undefined
    })
  },
  [fetchPresentationDispatchSubStatus.rejected]: (state, action) => {
    state.fetchPresentationDispatchSubStatus = handleRejectedAsyncThunk(state, action)
  },
  [createDefaultActivity.pending]: (state, action) => {
    state.isCreateDefaultActivity = true
  },
  [createDefaultActivity.fulfilled]: (state, action) => {
    state.isCreateDefaultActivity = false
  },
  [createDefaultActivity.rejected]: (state, action) => {
    state.isCreateDefaultActivity = false
  },
  [updatePositionActivity.pending]: (state, action) => {
    state.isMovingActivity = true
  },
  [updatePositionActivity.fulfilled]: (state, action) => {
    state.isMovingActivity = false
  },
  [updatePositionActivity.rejected]: (state, action) => {
    state.isMovingActivity = false
  },
  [fetchPresentationGroups.pending]: (state, action) => {
    const { meta: { arg: { page } } } = action
    if (page === 1) {
      state.fetchPresentationGroups = handlePendingAsyncThunk(state, action)
    }
  },
  [fetchPresentationGroups.fulfilled]: (state, action) => {
    const { options: currentOptions } = state.fetchPresentationGroups.data || {}

    const { options, meta } = action.payload
    const { event } = meta

    let groupOptions = options || []

    if (event === 'paging') {
      groupOptions = (Array.isArray(currentOptions) ? [...currentOptions, ...options] : options)
      const result = groupOptions.reduce((items, { label, options, id }) => {
        const uniqueIdentifier = `${label}-${id}`
        items[uniqueIdentifier] ??= { label, options: [], id }
        if (Array.isArray(options)) {
          items[uniqueIdentifier].options = items[uniqueIdentifier].options.concat(options)
        } else {
          items[uniqueIdentifier].options.push(options)
        }
        return items
      }, {})
      groupOptions = Object.values(result)
    }

    const optionsGroupIds = (groupOptions || []).map(
      groupCategory => groupCategory.options.map(group => group.value)
    ).flat()

    const data = {
      options: groupOptions || [],
      optionsGroupIds,
      meta
    }

    state.fetchPresentationGroups = ({
      data,
      status: derivedStatus('SUCCESS'),
      error: undefined
    })
  },
  [fetchPresentationGroups.rejected]: (state, action) => {
    state.fetchPresentationGroups = handleRejectedAsyncThunk(state, action)
  }
})

export default proofOfDeliveriesReducer
