import { createReducer } from '@reduxjs/toolkit'
import {
  fetchWebhooks, setAvailableResources, updateWebhook, createWebhook,
  deleteWebhook, fetchDetailWebhook } from '~actions/settings/webhooks'

import {
  createInitialState,
  handlePendingAsyncThunk,
  handleFulfilledAsyncThunk,
  handleRejectedAsyncThunk
} from '~utils'

const INITIAL_STATE = {
  fetchWebhooks: createInitialState(),
  detailWebhook: createInitialState(),
  availableResources: [],
  isSaving: false,
  isDeleting: false
}

export const webhooksReducer = createReducer(INITIAL_STATE, {
  [fetchWebhooks.pending]: (state, action) => {
    const { meta: { arg: { afterUpdate } = {} } } = action
    if (!afterUpdate) {
      state.fetchWebhooks = handlePendingAsyncThunk(state, action)
    }
  },
  [fetchWebhooks.fulfilled]: (state, action) => {
    state.fetchWebhooks = handleFulfilledAsyncThunk(state, action)
  },
  [fetchWebhooks.rejected]: (state, action) => {
    state.fetchWebhooks = handleRejectedAsyncThunk(state, action)
  },
  [fetchDetailWebhook.pending]: (state, actions) => {
    state.detailWebhook = handlePendingAsyncThunk(state, actions)
  },
  [fetchDetailWebhook.fulfilled]: (state, actions) => {
    state.detailWebhook = handleFulfilledAsyncThunk(state, actions)
  },
  [fetchDetailWebhook.rejected]: (state, actions) => {
    state.detailWebhook = handleRejectedAsyncThunk(state, actions)
  },
  [updateWebhook.pending]: (state, action) => {
    state.isSaving = true
  },
  [updateWebhook.fulfilled]: (state, action) => {
    state.isSaving = false
  },
  [updateWebhook.rejected]: (state, action) => {
    state.isSaving = false
  },
  [createWebhook.pending]: (state, action) => {
    state.isSaving = true
  },
  [createWebhook.fulfilled]: (state, action) => {
    state.isSaving = false
  },
  [createWebhook.rejected]: (state, action) => {
    state.isSaving = false
  },
  [deleteWebhook.pending]: (state, action) => {
    state.isDeleting = true
  },
  [deleteWebhook.fulfilled]: (state, action) => {
    state.isDeleting = false
  },
  [deleteWebhook.rejected]: (state, action) => {
    state.isDeleting = false
  },
  [setAvailableResources]: (state, action) => {
    state.availableResources = action.payload || []
  }
})

export default webhooksReducer
