export const ONDEMAND_ENGINE_PATHNAME = '/ondemand'
export const EVENT_TYPES = {
  DEFAULT: 'default',
  PAGING: 'paging',
  SORTING: 'sorting',
  FILTERING: 'filtering',
  SAVING: 'saving',
  DELETING: 'deleting'
}

export const SUB_STATUSES_STATUS_COLOR = {
  2: '#4BB839',
  3: '#E80504',
  4: '#DEC820'
}

export const SUB_STATUSES_BINNACLE_URL = '/settings/dispatch_sub_statuses/activities'
export const SUB_STATUSES_ARTICLE_LINK = 'https://support.beetrack.com/es/articles/3369966-lastmile-como-agregar-subestados'

export const STORE_MARKER_COLOR = '#546884'

export const APP_ROUTES = {
  'dispatch_sub_statuses': {
    index: '/settings/dispatch_sub_statuses',
    new: '/settings/dispatch_sub_statuses/new',
    edit: '/settings/dispatch_sub_statuses/:id/edit'
  },
  'trucks': {
    index: '/settings/trucks',
    new: '/settings/trucks/new',
    edit: '/settings/trucks/:id/edit',
    export: '/settings/trucks.xls',
    import: '/settings/trucks/import'
  },
  'widget': {
    index: '/settings/widget',
    new: '/settings/widget/new',
    edit: '/settings/widget/:id/edit',
    traffic: '/settings/widget/:id/traffic'
  },
  'places': {
    index: '/settings/places',
    new: '/settings/places/new',
    edit: '/settings/places/:id/edit',
    log: '/settings/places/activities'
  }
}

export const HTTP_ERRORS = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  BAD_REQUEST: 400
}

export const COLOR_PRESETS_HEX = [
  '#f5b63e', '#ffd200', '#eea00c', // $hp-primary
  '#546884', '#a3b5d1', '#222e3f', // $hp-secondary
  '#0792e0', '#70cbff', '#197db5', // $hp-tertiary
  '#4bb839', '#5bde45', // $hp-success
  '#dec820', '#f7df24', // $hp-warning
  '#fc3e3e', '#fc6161', // $hp-danger
  '#707070' // $hp-grey
]

export const DISPATCH_STATUSES = {
  1: 'pending',
  2: 'delivered',
  3: 'not-delivered',
  4: 'partial'
}

export const STEP_COLOR_WIDGET_RESULT = {
  1: 'default', // pendiente
  2: 'success', // entregado
  3: 'error', // no entregado
  4: 'warning' // entrega parcial
}

export const MANAGED_DISPATCH_STATUSES = [2, 3, 4]

export const NOTIFICATION_TYPE = {
  'notification_email': 'email',
  'notification_sms': 'phone'
}

export const DATE_FORMAT = 'dd/MM/yyyy'
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm'
export const DATE_TIME_FORMAT_API = 'yyyy-MM-dd HH:mm:ss'
export const TIME_FORMAT = 'HH:mm'

export const URL_LOGO = 'https://beetrack-general.s3.us-west-2.amazonaws.com/static/mailings/signature/dispatch-track-beetrack-logo.png'
