import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Select } from '@beetrack/hp-components'

import { selectOrders } from '~selectors/widget_result'
import { setOrderSelected } from '~actions/widget_result'

const WidgetResultOrder = () => {
  const { t } = useTranslation()
  const orders = useSelector(selectOrders)
  const [selected, setSelected] = useState(orders[0]?.value)
  const dispatch = useDispatch()

  const onChangeSelect = (option) => {
    setSelected(option.value)
    dispatch(setOrderSelected(option.value))
  }

  return (
    <div className='widget-result__order'>
      {orders.length > 1 ? (
        <>
          <Select
            label={t('components:widget-result.order.label')}
            options={orders}
            value={selected}
            onChange={(option) => onChangeSelect(option)}
            name='order'
            showHelperSpace={false}
          />
        </>
      ) : (
        <label className='widget-result__order--label'>
          {t('components:widget-result.order.title')} #{orders[0]?.label}
        </label>
      )}
    </div>
  )
}

export default WidgetResultOrder
