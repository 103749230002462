
import { entitiesStore, getEntity, ENTITY } from '~selectors/entities'
import { getDark } from '~selectors/layout'

const webhooksStore = (state) => state.settings.webhooks || {}

// eslint-disable-next-line max-lines-per-function
export const selectWebhooks = (state) => {
  const { fetchWebhooks: { data: webhooksIds, status, error }, isSaving, isDeleting } = webhooksStore(state)
  const getWebhook = (id) => getEntity(ENTITY.webhook, id)(state)
  const getWebhookEvent = (id) => getEntity(ENTITY.webhookEvent, id)(state)

  const webhooks = webhooksIds?.map((webhookId, webhookIndex) => {
    const webhook = getWebhook(webhookId)

    const webhookEvents = webhook?.outbound_configuration_resources?.map(getWebhookEvent) || []
    const routeEvents = webhookEvents.filter(webhookEvent => webhookEvent.resource === 'route')
    const dispatchEvents = webhookEvents.filter(webhookEvent => webhookEvent.resource === 'dispatch')
    const dispatchGuideEvents = webhookEvents.filter(webhookEvent => webhookEvent.resource === 'dispatch_guide')
    const reviewEvents = webhookEvents.filter(webhookEvent => webhookEvent.resource === 'review')

    const formValues = {
      id: webhook.id,
      url: webhook.url,
      headers: Object.keys(webhook.headers || {}).map(key => ({
        key,
        value: webhook.headers[key],
        readOnly: key === 'Content-Type' && webhook.headers[key] === 'application/json'
      })),
      events: {}
    }

    webhookEvents.forEach(webhookEvent => {
      if (!formValues.events[webhookEvent.resource]) {
        formValues.events[webhookEvent.resource] = {}
      }
      formValues.events[webhookEvent.resource][webhookEvent.event] = {
        id: webhookEvent.id,
        [webhookEvent.event]: true,
        extraParametter: webhookEvent.extra_attributes
      }
    })

    // ReadOnly headers first
    formValues.headers.sort((h1, h2) => h2.readOnly - h1.readOnly)

    return {
      ...webhook,
      key: `webhook-${webhook.id}`,
      number: webhookIndex + 1,
      routeEvents,
      dispatchEvents,
      dispatchGuideEvents,
      reviewEvents,
      formValues
    }
  }) || []

  // Append available slots for webhooks
  for (let n = webhooks.length; n < 4; n = n + 1) {
    webhooks.push({
      isAvailable: true,
      key: `available-slot-${n}`,
      number: n + 1,
      formValues: {
        url: '',
        headers: [
          { key: 'Content-Type', value: 'application/json', readOnly: true }
        ],
        events: {}
      }
    })
  }

  const disabledButtons = isSaving || isDeleting

  const modelName = ENTITY.webhook

  return {
    webhooks,
    status,
    error,
    disabledButtons,
    isSaving,
    isDeleting,
    modelName
  }
}

export const selectWebhookResources = (state) => {
  const { availableResources, detailWebhook } = webhooksStore(state)
  const resources = entitiesStore(state)?.[ENTITY.webhookResource]
  const dark = getDark(state)

  return {
    detailWebhookStatus: detailWebhook.status,
    detailWebhookData: detailWebhook.data || undefined,
    availableResources,
    resources,
    dark
  }
}
