import { createReducer } from '@reduxjs/toolkit'

import * as placesActions from '~actions/settings/places'

import { fetchInitialState, handleFetchReducer,
  handlePageFulfilled, pagedInitialState } from '~reducers/shared'

const INITIAL_STATE = {
  ...fetchInitialState,
  ...pagedInitialState,
  indexLoadingItems: [],
  place: undefined
}

export const placesReducer = createReducer(INITIAL_STATE, (builder) =>
  builder
    .addCase(placesActions.deletePlace.pending, (state, { meta: { arg: { deleteItems } } }) => {
      const loadingItemsIndex = deleteItems.map(({ index }) => index)
      state.indexLoadingItems = loadingItemsIndex
    })
    .addCase(placesActions.deletePlace.fulfilled, (state, action) => {
      state.indexLoadingItems = []
    })
    .addCase(placesActions.deletePlace.rejected, (state, action) => {
      state.indexLoadingItems = []
    })
    .addCase(placesActions.getPlace.fulfilled, (state, { payload }) => {
      state.place = payload
    })
    .addMatcher(...handleFetchReducer(placesActions.fetchPlaces))
    .addMatcher(...handlePageFulfilled(placesActions.fetchPlaces))
    .addMatcher(...handleFetchReducer(placesActions.getPlace))
    .addMatcher(...handleFetchReducer(placesActions.createPlace))
    .addMatcher(...handleFetchReducer(placesActions.updatePlace))
)

export default placesReducer
