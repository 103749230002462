import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { Geocoder } from '@beetrack/map-services'

import { TextField, List, ListItem, hpUtils } from '@beetrack/hp-components'
import { Pin } from '@beetrack/hp-icons-react'

import { HERE_API_KEY } from 'frontend/config/map_config'

import { getDark } from '~selectors/layout'

const AddressInput = (props) => {
  const {
    additionalClassName,
    label,
    placeholder,
    fromIsSelected,
    disabled,
    required,
    value,
    onChange,
    onSelect,
    name
  } = props
  const dark = useSelector(getDark)
  const [fromSelect, setFromSelect] = useState(fromIsSelected)
  const [loading, setLoading] = useState(false)
  const [address, setAddress] = useState({})
  const [focus, setFocus] = useState(false)
  const [error, setError] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    if (fromSelect) return
    const timeout = setTimeout(() => {
      if (value.length > 0) {
        findGeolocation(value)
        openMenu()
      } else {
        closeMenu()
      }
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [value])

  const handleChange = (e) => {
    setFromSelect(false)
    onChange(e)
  }

  const findGeolocation = (searchString) => {
    setLoading(true)
    const geocoder = new Geocoder({
      kind: window.Constants.GEOCODING(),
      apiKey: HERE_API_KEY
    })

    geocoder.geocode({
      query: searchString,
      callback: (result, status) => {
        setAddress(result)
        setError(status)
        setLoading(false)
      }
    })
  }

  const suggestionsContent = () => {
    if (loading) return <ListItem content={t('components:address-input.loading')} />

    if (error === 'NO_RESULTS') return <ListItem content={t('components:address-input.not-result')} />

    return (
      <ListItem
        content={address?.name}
        onMouseDown={handleSelect}
        selected
      />
    )
  }

  const suggestions = () => {
    if (!focus) return null
    return (
      <List dark={dark} additionalClassName='address-input__list' >
        {suggestionsContent()}
      </List>
    )
  }

  const closeMenu = () => setFocus(false)

  const openMenu = () => setFocus(true)

  const handleSelect = () => {
    setFromSelect(true)
    closeMenu(false)
    onSelect(address)
  }

  return (
    <div className={hpUtils.getBEMClasses('address-input', { focus }, additionalClassName)}>
      <OutsideClickHandler onOutsideClick={closeMenu}>
        <TextField
          dark={dark}
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          trailingIcon={<Pin />}
          showHelperSpace={false}
          disabled={disabled}
          required={required}
          name={name}
        />
        {suggestions()}
      </OutsideClickHandler>
    </div >
  )
}

AddressInput.defaultProps = {
  value: '',
  onSelect: () => {},
  onChange: () => { },
  fromIsSelected: false
}

AddressInput.propTypes = {
  additionalClassName: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fromIsSelected: PropTypes.bool
}

export default AddressInput
