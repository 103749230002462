import { isAsyncThunkAction, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'

import { getSubObject } from '~utils'
import { EVENT_TYPES } from '~constants'
import { isObjectEmpty } from '~utils/objects'

export const handleFetchReducer = (fetchAction, keyName) => [
  isAsyncThunkAction(fetchAction),
  (fullState, action) => {
    const state = getSubObject(fullState, keyName)

    if (action.type.includes('/pending')) {
      state.loading = action.meta?.arg?.event || EVENT_TYPES.DEFAULT
      state.error = null
    }

    if (action.type.includes('/fulfilled')) {
      state.loading = null
      state.error = null
    }

    if (action.type.includes('/rejected')) {
      state.loading = null
      state.error = action.payload
    }
  }
]

export const handlePageFulfilled = (fetchAction, keyName) => [
  isFulfilled(fetchAction),
  (fullState, action) => {
    const { pagination, meta = {}, ids = {} } = action.payload
    const event = action.meta?.arg?.event
    const state = getSubObject(fullState, keyName)
    if (event !== EVENT_TYPES.PAGING) state.ids = {}

    state.pagination = pagination
    state.meta = meta

    if (pagination) {
      const items = {}
      const baseIndex = (pagination.currentPage - 1) * pagination.pageSize

      !isObjectEmpty(ids) && ids.forEach((item, index) => {
        items[baseIndex + index] = item
      })

      state.ids = { ...state.ids, ...items }
    }
  }
]

export const handleFetchSelectOptions = (fetchAction, keyName) => [
  isAsyncThunkAction(fetchAction),
  (fullState, action) => {
    const state = getSubObject(fullState, keyName)

    if (isPending(action)) {
      state.loading = EVENT_TYPES.DEFAULT
      state.error = null
    }

    if (isFulfilled(action)) {
      state.loading = null
      state.error = null
      state.options = action?.payload || []
    }

    if (isRejected(action)) {
      state.loading = null
      state.error = action.payload
    }
  }
]

export const fetchInitialState = {
  error: null,
  loading: EVENT_TYPES.DEFAULT
}

export const pagedInitialState = {
  ids: {},
  pagination: {},
  meta: {}
}
