import * as Yup from 'yup'
import { isEmail, isRequired, isValidPhoneNumber, mergeValidations } from '~utils/validations'

export const validationSchema = () => {
  return Yup.object().shape({
    name: mergeValidations(
      isRequired(),
      Yup.string().when('channel_type', {
        is: 'notification_email',
        then: isEmail(),
        otherwise: isValidPhoneNumber()
      })
    )
  })
}
