import { hpLangs } from '@beetrack/hp-components'

import legacyResources from '../../../localization/resources'
import esLang from './es'
import enLang from './en'

export default {
  es: {
    ...legacyResources.es,
    honeypot: hpLangs.es,
    ...esLang
  },
  en: {
    ...legacyResources.en,
    honeypot: hpLangs.en,
    ...enLang
  }
}
