import { capitalize } from '~utils/utils'

export const parsePlacesRequest = ({ data }) => {
  const place = data.data
  const { attributes } = place

  return { ...attributes, id: place.id }
}

export const parsePlaceErrors = (errorResponse = {}) => {
  const { resource } = errorResponse
  let errors = {}
  Object.entries(resource).forEach(([field, text]) => {
    errors = { ...errors, [field]: capitalize(text[0], false) }
  })
  return errors
}
