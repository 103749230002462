import { configureStore, combineReducers } from '@reduxjs/toolkit'

import layout from './layout'
import entities from './entities'

import routes from './temporal_link/routes'
import account from './settings/account'
import places from './settings/places'
import proofOfDeliveries from './settings/forms'
import widget from './settings/widget'
import trucks from './settings/trucks'
import webhooks from './settings/webhooks'
import subStatuses from './settings/sub_statuses'
import widgetResult from './widget_result'

// all playground related code is only for developers
import { playgroundReducer } from '~pages/playground/playground_page'

// Please, add new reducers in alphabetical order
export const rootReducer = combineReducers({
  entities,
  layout,
  playground: playgroundReducer,
  settings: combineReducers({
    account,
    places,
    proofOfDeliveries,
    subStatuses,
    trucks,
    webhooks,
    widget
  }),
  temporalLink: combineReducers({
    routes
  }),
  widgetResult
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: { name: 'Beetrack: Gen2' }
})

export default store
