import { createAsyncThunk } from '@reduxjs/toolkit'
import normalizeJsonAPI from '@beetrack/jsonapi-normalizer'

import { storeEntities } from '../entities'
import * as api from '~api/temporal_link/routes'
import { ENTITY } from '~selectors/entities'

const NAMESPACE = 'routes'

export const fetchRoutes = createAsyncThunk(
  `${NAMESPACE}/fetchRoutes`,
  async (token, { dispatch }) => {
    try {
      const response = await api.fetchRoutes(token)
      const normalized = normalizeJsonAPI(response.data, true)
      dispatch(storeEntities(normalized))
      return normalized.result[ENTITY.route]
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
)
