export const capitalize = (word = '') => {
  const lower = word.toLowerCase()
  return `${lower.charAt(0).toUpperCase()}${lower.slice(1)}`
}

export const snakeToCamelCase = (snakeName = '') =>
  snakeName
    .split('_')
    .map((word, index) => (index > 0 ? capitalize(word) : word.toLowerCase()))
    .join('')

export const objectKeysToCamelCase = (objectWithSnakeCaseKeys = {}) => {
  const objectWithCamelCaseKeys = {}
  const keys = Object.keys(objectWithSnakeCaseKeys)
  keys.forEach((snakeKey) => {
    objectWithCamelCaseKeys[snakeToCamelCase(snakeKey)] =
      objectWithSnakeCaseKeys[snakeKey]
  })
  return objectWithCamelCaseKeys
}

export const objectOrNull = object => {
  if (object && typeof object === 'object' && Object.keys(object).length > 0) {
    return object
  }
  return null
}

export const redirectTo = (url = '/') => { window.location.href = url }

export const rgbaToHex = (rgba) => {
  const match = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/)
  if (!match) {
    return rgba
  }

  let r = parseInt(match[1])
  let g = parseInt(match[2])
  let b = parseInt(match[3])
  let a = match[4] ? Math.round(parseFloat(match[4]) * 255) : 255

  r = r.toString(16).padStart(2, '0')
  g = g.toString(16).padStart(2, '0')
  b = b.toString(16).padStart(2, '0')
  a = a.toString(16).padStart(2, '0')

  return `#${r}${g}${b}${a}`
}
