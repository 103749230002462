import qs from 'qs'

export const URLToParams = (url, options = {}) => {
  if (typeof url !== 'string') return {}

  return qs.parse(url.substring(1), options)
}

export const paramsToURL = params => {
  if (typeof params !== 'object' || Array.isArray(params)) return ''
  return qs.stringify(params, { encodeValuesOnly: true })
}
