import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import * as api from '~api/settings/trucks'
import deepMerge from 'deepmerge'

import { parseFetchResponse } from '~parsers'
import { openSnackbar } from '~actions/layout'
import i18n from '~localization/index'
import { parseTruckErrors, parseTruckRequest } from '~utils/form_parsers/trucks'

import { parsePaginationAndMeta, parseResponseErrors } from 'frontend/parsers'

const NAMESPACE = 'SETTINGS'

export const resetState = createAction(`${NAMESPACE}/resetState`)
export const resetTablePagesExcept = createAction(`${NAMESPACE}/resetTablePagesExcept`)
export const setTrucksFilters = createAction(`${NAMESPACE}/setTrucksFilters`)

export const fetchTrucks = createAsyncThunk(
  `${NAMESPACE}/fetchTrucks`,
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.fetchTrucks(args.params)
      const normalized = parseFetchResponse(response)
      return normalized
    } catch (error) {
      console.error(error)
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const deleteTruck = createAsyncThunk(
  `${NAMESPACE}/deleteTruck`,
  async ({ deleteItems, visiblePages, params = {} }, { dispatch, rejectWithValue }) => {
    const [{ id }] = deleteItems

    try {
      await api.deleteTruck(id)
      const promises = visiblePages.map(page => api.fetchTrucks({ ...params, page }))
      const responses = await Promise.all(promises)
      const data = deepMerge.all(responses.map(response => parseFetchResponse(response)))
      data.pagination = parsePaginationAndMeta(responses[0].data).pagination

      dispatch(fetchTrucks.fulfilled(data))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:trucks.messages.delete-success')
      }))
    } catch (error) {
      console.error(error)
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:trucks.messages.delete-error')
      }))
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const fetchTruckGroups = createAsyncThunk(
  `${NAMESPACE}/fetchTruckGroups`,
  async () => {
    try {
      const response = await api.fetchTruckGroups()
      const normalized = parseTruckRequest(response)
      return normalized
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
)

export const fetchTruckDrivers = createAsyncThunk(
  `${NAMESPACE}/fetchTruckDrivers`,
  async () => {
    try {
      const response = await api.fetchTruckDrivers()
      return response
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
)

export const createTruck = createAsyncThunk(
  `${NAMESPACE}/createTruck`,
  async ({ ...params }, { dispatch, rejectWithValue }) => {
    try {
      await api.createTruck(params)
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:truck-form.messages.create-success')
      }))
    } catch (error) {
      console.error(error)
      if (error?.response?.status === 422) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:truck-form.messages.fields-error')
        }))
        return rejectWithValue(parseTruckErrors(error?.response?.data))
      } else {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:truck-form.messages.create-error')
        }))
        return rejectWithValue(parseResponseErrors(error))
      }
    }
  }
)

export const getTruck = createAsyncThunk(
  `${NAMESPACE}/getTruck`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.getTruck(id)
      return response
    } catch (error) {
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const updateTruck = createAsyncThunk(
  `${NAMESPACE}/updateTruck`,
  async ({ id, ...params }, { dispatch, rejectWithValue }) => {
    try {
      await api.updateTruck(id, params?.data)
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:truck-form.messages.edit-success')
      }))
    } catch (error) {
      console.error(error)
      if (error?.response?.status === 422) {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:truck-form.messages.fields-error')
        }))
        return rejectWithValue(parseTruckErrors(error?.response?.data))
      } else {
        dispatch(openSnackbar({
          type: 'error',
          message: i18n.t('pages:truck-form.messages.edit-error')
        }))
        return rejectWithValue(parseResponseErrors(error))
      }
    }
  }
)
