const OSM_TILE_CONFIG = {
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  options: {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }
}

export const HERE_API_KEY = process.env.HERE_API_KEY

const HERE_OPTIONS = {
  attribution: `Map Tiles &copy; ${new Date().getFullYear()} <a href="http://developer.here.com">HERE</a>`,
  apiKey: HERE_API_KEY,
  tile: 'mapnopttile',
  version: 'newest',
  style: 'default',
  scheme: 'reduced.day',
  size: '256',
  format: 'png8'
}
const HERE_TILE_CONFIG = {
  url: 'https://2.base.maps.ls.hereapi.com/maptile/2.1/{tile}/{version}/{scheme}/{z}/{x}/{y}/{size}/{format}?style={style}&apiKey={apiKey}',
  options: HERE_OPTIONS,
  fallback: {
    url: OSM_TILE_CONFIG.url,
    attribution: OSM_TILE_CONFIG.options.attribution,
    statusUrl: `https://2.base.maps.ls.hereapi.com/maptile/2.1/${HERE_OPTIONS.tile}/${HERE_OPTIONS.version}/${HERE_OPTIONS.scheme}/0/0/0/${HERE_OPTIONS.size}/${HERE_OPTIONS.format}?style=${HERE_OPTIONS.style}&apiKey=${HERE_OPTIONS.apiKey}`
  }
}
const TILE = 'here' // maybe later can be readed from the html body
export const MAP_TILE_CONFIG = TILE === 'here' ? HERE_TILE_CONFIG : OSM_TILE_CONFIG
export const MAP_CENTER = { // By default, center on Santiago, Chile
  lat: -33.4377968,
  lng: -70.6504451
}

export const COLOR_POINT_FINAL_DESTINATION = {
  0: '#aaa', /// null o undefined
  1: '#aaa', // pendiente
  2: '#4bb839', // entregado
  3: '#DB4437', // no entregado
  4: '#dec820' // entrega parcial
}
export const COLOR_POINT_DRIVER = '#0792E0'

export const GOOGLE_MAP_STYLES = [
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [{ 'visibility': 'off' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ 'saturation': -100 }, { 'lightness': 50 }]
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [{ 'saturation': -100 }, { 'lightness': 50 }]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [{ 'color': '#F2F2F2' }]
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [{ 'color': '#DDE6EC' }]
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [{ 'color': '#9A7A56' }]
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.stroke',
    stylers: [{ 'visibility': 'off' }]
  }
]
