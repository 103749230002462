import * as api from '~api/widget_result'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import normalizeJsonAPI from '@beetrack/jsonapi-normalizer'
import { parseResponseErrors } from 'frontend/parsers'

const NAMESPACE = 'WIDGET_RESULT'

export const setDispatchGuide = createAction(`${NAMESPACE}/setDispatchGuide`)

export const setWidgetConfig = createAction(`${NAMESPACE}/setWidgetConfig`)

export const setWidgetKey = createAction(`${NAMESPACE}/setWidgetKey`)

export const setOrderSelected = createAction(`${NAMESPACE}/setOrderSelected`)

export const setStepIndex = createAction(`${NAMESPACE}/setStepIndex`)

export const resetIdsWaypoint = createAction(`${NAMESPACE}/resetIdsWaypoint`)

export const fetchNotificationChannels = createAsyncThunk(
  `${NAMESPACE}/fetchNotificationChannels`,
  async ({ key, dispatchId }, { rejectWithValue }) => {
    try {
      const response = await api.fetchNotificationChannels(key, dispatchId)
      const normalize = normalizeJsonAPI(response.data)
      return normalize
    } catch (error) {
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const addRecipient = createAsyncThunk(
  `${NAMESPACE}/addRecipient`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.addRecipient(params)
      const normalize = normalizeJsonAPI(response.data)
      return normalize
    } catch (error) {
      const { response } = error
      return rejectWithValue({ name: response.data.error })
    }
  }
)

export const removeRecipient = createAsyncThunk(
  `${NAMESPACE}/removeRecipient`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.removeRecipient(params)
      const normalize = normalizeJsonAPI(response.data)
      return normalize
    } catch (error) {
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const fetchWaypoints = createAsyncThunk(
  `${NAMESPACE}/fetchWaypoints`,
  async ({ key, dispatchId, lastWaypointId }, { rejectWithValue }) => {
    const params = lastWaypointId ? { 'dispatch': dispatchId,
      'last_waypoint_id': lastWaypointId }
      : { 'dispatch': dispatchId }
    try {
      const response = await api.fetchWaypoints(key, params)
      const normalize = normalizeJsonAPI(response.data)
      return normalize
    } catch (error) {
      const { response } = error
      return rejectWithValue(response)
    }
  }
)
