import normalizeJsonAPI from '@beetrack/jsonapi-normalizer'

const parseProducts = (products, hasOndemand) => {
  // This can contain only PlannerPro (or not)
  const parsedProducts = products.map(product => ({
    id: product.id,
    url: product.url,
    isPurchased: product.is_purchased,
    isDisabled: product.is_disabled
  }))
  // Add LastMile at the begginnig
  parsedProducts.unshift({
    id: 'lastmile',
    // use protocol for normal link redirect to gen1
    url: `${window.location.protocol}//${window.location.host}/`,
    isPurchased: true,
    isDisabled: false
  })
  if (hasOndemand) {
    // Add OnDemand at the end
    parsedProducts.push({
      id: 'ondemand',
      url: `/ondemand`,
      isPurchased: true,
      isDisabled: false
    })
  }
  return parsedProducts
}

export function parseUserData (response) {
  const { data: { meta }, included } = response

  let normalized = normalizeJsonAPI(response, true)
  const account = included[0]

  const parsedData = {
    result: normalized.result,
    user: normalized.entities.user[normalized.result.user[0]],
    accounts: meta.linked_resources,
    products: parseProducts(meta.products),
    accountPicture: account.attributes.account_picture,
    permissions: meta.permissions,
    lastReports: meta.last_reports,
    language: account.attributes.language,
    settings_permissions: meta.settings_permissions,
    active_mobile_users_count: included[0].meta.active_mobile_users_count,
    active_users_count: included[0].meta.active_users_count,
    hasOndemand: included[0].attributes.has_ondemand,
    enableLocks: included[0].attributes.enable_locks,
    showMode: included[0].meta.show_mode,
    showReverseLogistics: included[0].meta.show_reverse_logistics,
    planningMode: account.attributes.planning_mode
  }

  return parsedData
}
