import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { hpUtils } from '@beetrack/hp-components'

import { selectItems } from '~selectors/widget_result'

import { sum } from '~utils/arrays'
import { isObjectEmpty } from '~utils/objects'

export const WidgetResultItems = (props) => {
  const { itemsId, dispatch = { 'dispatch_items': [] }, showDelivered } = props
  const { t } = useTranslation()
  const { dispatch_items: dispatchItems } = dispatch
  const items = useSelector(selectItems(itemsId))
  const [showQuantityRef, setShowQuantityRef] = useState(false)

  useEffect(() => {
    const quantityRef = items.find(item => item.quantity_ref)
    const show = !isObjectEmpty(quantityRef) && quantityRef !== undefined
    setShowQuantityRef(show)
  }, [items])

  const getDeliveryQuantity = (name, typeOfQuantity = 'delivered_quantity') => {
    const deliveryQuantity = dispatch?.dispatch_items?.find(item => item.name === name)?.[typeOfQuantity]
    return deliveryQuantity
  }

  const getColor = (quantity, deliveredQuantity) => {
    if (deliveredQuantity === undefined) return
    if (deliveredQuantity === 0) return 'red'
    if (deliveredQuantity === quantity) return 'green'
    if (deliveredQuantity > 0 || deliveredQuantity > quantity) return 'yellow'
  }

  const showQuantity = (item) => {
    const quantity = item.quantity
    const deliveredQuantity = getDeliveryQuantity(item.name)
    const deliveredQuantityRef = getDeliveryQuantity(item.name, 'delivered_quantity_ref')
    const color = getColor(quantity, deliveredQuantity)
    const colorRef = getColor(quantity, deliveredQuantityRef)

    return (
      <>
        <div className={hpUtils.getBEMClasses('widget-result-items__item-quantity', { [color]: color })}>
          {showDelivered && `${deliveredQuantity}/`}{item.quantity}
        </div>
        {showQuantityRef && (
          <div className={hpUtils.getBEMClasses('widget-result-items__item-quantity', { [colorRef]: colorRef })}>
            {showDelivered && `${deliveredQuantityRef}/`}
            {item?.quantity_ref}
          </div>
        )}
      </>
    )
  }

  const total = sum(items, 'quantity')
  const totalDelivered = sum(dispatchItems, 'delivered_quantity')
  const totalRef = sum(items, 'quantity_ref').toFixed(1)
  const totalDeliveredRef = sum(dispatchItems, 'delivered_quantity_ref').toFixed(1)

  return (
    <div className='widget-result-items'>
      <label>{t('components:widget-result.information.items')}</label>
      {items.map((item, index) =>
        <div key={index} className='widget-result-items__item'>
          <div className='widget-result-items__item-name'>{item.name}</div>
          {showQuantity(item)}
        </div>
      )}
      <div className='widget-result-items__item'>
        <div className={hpUtils.getBEMClasses('widget-result-items__item-name', { total: true })}>
          {t('components:widget-result.information.total')}
        </div>
        <div className={hpUtils.getBEMClasses('widget-result-items__item-quantity', { total: true })}>
          {showDelivered && `${totalDelivered}/`}{total}
        </div>
        {totalRef && showQuantityRef && (
          <div className={hpUtils.getBEMClasses('widget-result-items__item-quantity', { total: true })}>
            {showDelivered && `${totalDeliveredRef}/`}{totalRef}
          </div>
        )}
      </div>
    </div>
  )
}

export default WidgetResultItems
