import axios from '../config'
import { getGroupCategoriesOptions } from '~utils/mappers/settings/trucks'

export const fetchWidgets = (params) => axios.get(`/account/widgets`, { params })

export const getWidget = (id) => axios.get(`/account/widgets/${id}`)

export const createWidget = (params) => axios.post(`/settings/widget.json`, { widget: params })

export const deleteWidget = (id) => axios.delete(`/settings/widget/${id}`)

export const updateWidget = (id, params) => axios.patch(`/settings/widget/${id}.json`, { widget: params })

export const fetchWidgetConditions = () => axios.get('/settings/widget/conditions', {})

export const fetchWidgetGroups = () =>
  axios
    .get('/account/group_categories?include_groups=true&visible=true&no_paginate=true')
    .then(getGroupCategoriesOptions)

export const fetchWidgetTraffic = ({ params }, id) => axios.get(`/settings/widget/${id}/statistics`, { params })
