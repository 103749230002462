import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Marker, SVGMarkers } from '@beetrack/hp-components'

import { selectOrderSelected, selectWaypoint } from '~selectors/widget_result'
import { resetIdsWaypoint } from '~actions/widget_result'

import { simulationBetweenTwoPoints } from '~utils/simulation_between_points'
import { COLOR_POINT_DRIVER } from 'frontend/constants/map'

export const WidgetResultDriverSimulator = ({ ...otherProps }) => {
  const [simulations, setSimulations] = useState({})
  const [startPoint, setStartPoint] = useState(0)
  const [endPoint, setEndPoint] = useState(1)
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [isMoving, setIsMoving] = useState(false)
  const driverWaipoints = useSelector(selectWaypoint)
  const orderSelected = useSelector(selectOrderSelected)
  const startWaipoint = driverWaipoints[startPoint]
  const endWaipoint = driverWaipoints[endPoint]
  const dispatch = useDispatch()
  let valueTimeOut

  const getSimulations = async () => {
    for (let i = 0; i < driverWaipoints.length - 1; i++) {
      const start = driverWaipoints[i]
      const end = driverWaipoints[i + 1]
      if (!simulations[`${start.id}->${end.id}`]) {
        simulationBetweenTwoPoints(start, end)
          .then(positionsBetween => {
            setSimulations((prevSimulations) => ({
              ...prevSimulations,
              [`${start.id}->${end.id}`]: {
                origin: start.id,
                destination: end.id,
                positionsBetween
              }
            }))
          })
      }
    }
  }

  const changePositions = (positions, index) => {
    if (index < positions.length) {
      setLatitude(positions[index].lat)
      setLongitude(positions[index].lng)
      valueTimeOut = setTimeout(() => changePositions(positions, index + 1), positions[index].duration)
    } else {
      setIsMoving(false)
      setStartPoint(startPoint + 1)
      setEndPoint(endPoint + 1)
    }
  }

  useEffect(() => {
    getSimulations()
    // Set position in first waypoint at the begining
    if (driverWaipoints.length < 0 && !latitude && !longitude) {
      setLatitude(driverWaipoints[0].lat)
      setLongitude(driverWaipoints[0].lng)
    }
  }, [driverWaipoints])

  useEffect(() => {
    if (Object.keys(simulations).length > 0 && endPoint < driverWaipoints.length) {
      // Move driver to startWaipoint
      if (!isMoving) {
        setLatitude(startWaipoint.lat)
        setLongitude(startWaipoint.lng)
        window.localStorage.removeItem('lastWaypoint')
        window.localStorage.setItem('lastWaypoint',
          JSON.stringify({ orderSelectedId: orderSelected, idWaipoint: startWaipoint.id }))
      }

      const positions = simulations[`${startWaipoint.id}->${endWaipoint.id}`]?.positionsBetween || []
      // If exists simulation between current start and end points
      if (positions.length > 0 && !isMoving) {
        setIsMoving(true)
        changePositions(positions, 0)
      }
    }
  }, [simulations, endPoint])

  useEffect(() => {
    return () => {
      clearTimeout(valueTimeOut)
      dispatch(resetIdsWaypoint())
    }
  }, [])

  if (!latitude && !longitude) return (<></>)

  return (
    <Marker
      id='driver-point'
      key='driver-point'
      position={{ lat: latitude, lng: longitude }}
      icon={<SVGMarkers.Circle color={COLOR_POINT_DRIVER} />}
      {...otherProps}
    />
  )
}

export default WidgetResultDriverSimulator
