import { createReducer } from '@reduxjs/toolkit'
import * as trucksActions from '~actions/settings/trucks'

import { fetchInitialState, handleFetchReducer,
  handlePageFulfilled, pagedInitialState, handleFetchSelectOptions } from '~reducers/shared'

const INITIAL_STATE = {
  ...fetchInitialState,
  ...pagedInitialState,
  indexLoadingItems: [],
  truck: undefined,
  truckGroupsOptions: {
    options: [],
    loading: false
  },
  truckDriversOptions: {
    options: [],
    loading: false
  }
}

export const trucksReducer = createReducer(INITIAL_STATE, (builder) =>
  builder
    .addCase(trucksActions.deleteTruck.pending, (state, { meta: { arg: { deleteItems } } }) => {
      const loadingItemsIndex = deleteItems.map(({ index }) => index)
      state.indexLoadingItems = loadingItemsIndex
    })
    .addCase(trucksActions.deleteTruck.fulfilled, (state, action) => {
      state.indexLoadingItems = []
    })
    .addCase(trucksActions.deleteTruck.rejected, (state, action) => {
      state.indexLoadingItems = []
    })
    .addCase(trucksActions.getTruck.fulfilled, (state, { payload }) => {
      state.truck = payload
    })
    .addMatcher(...handleFetchReducer(trucksActions.fetchTrucks))
    .addMatcher(...handlePageFulfilled(trucksActions.fetchTrucks))
    .addMatcher(...handleFetchSelectOptions(trucksActions.fetchTruckGroups, 'truckGroupsOptions'))
    .addMatcher(...handleFetchSelectOptions(trucksActions.fetchTruckDrivers, 'truckDriversOptions'))
    .addMatcher(...handleFetchReducer(trucksActions.getTruck))
    .addMatcher(...handleFetchReducer(trucksActions.createTruck))
    .addMatcher(...handleFetchReducer(trucksActions.updateTruck))
)

export default trucksReducer
