import { createAsyncThunk, createAction } from '@reduxjs/toolkit'

import * as api from '~api/profile'
import { parseUserData } from '~parsers'

const NAMESPACE = 'layout'

export const toggleSidebar = createAction(`${NAMESPACE}/toggleSidebar`)

export const openSnackbar = createAction(`${NAMESPACE}/openSnackbar`)
export const closeSnackbar = createAction(`${NAMESPACE}/closeSnackbar`)

export const toggleDark = createAction(`${NAMESPACE}/toggleDark`)

export const getCurrentUser = createAsyncThunk(
  `${NAMESPACE}/getCurrentUser`,
  async () => {
    try {
      const response = await api.currentUser()
      const parsedUser = parseUserData(response.data)
      return parsedUser
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
)
