import React from 'react'
import PropTypes from 'prop-types'

import { DateRangePicker } from '@beetrack/hp-components'
import { useFilterBarField } from '@beetrack/hp-filter-bar-react'

const DatePickerFilter = (inputProps) => {
  const [fbFieldProps, fbContext] = useFilterBarField(inputProps.name)

  return (
    <DateRangePicker
      {...inputProps}
      {...fbFieldProps}
      {...fbFieldProps.value}
      {...fbFieldProps.dateRange?.key}
      dense
      style='box'
      onChange={(range) => fbContext.setFilterValue(inputProps.name, range)}
    />
  )
}

DatePickerFilter.propTypes = {
  ...DateRangePicker.propTypes,
  name: PropTypes.string.isRequired
}

export default DatePickerFilter
