import { useEffect, useRef } from 'react'

export const usePolling = ({ callback = () => {}, time = 60000 }) => {
  const pollingRef = useRef(null)
  const init = (...params) => {
    // execute callback always on first time.
    callback(params[0])
    // if interval exists, clear it.
    if (pollingRef.current) {
      clearInterval(pollingRef.current)
    }
    // start polling.
    pollingRef.current = setInterval(() => callback(params[0]), time)
  }

  useEffect(() => {
    return () => {
      // remove polling
      clearInterval(pollingRef.current)
    }
  }, [])

  return {
    init,
    pollingRef,
    // remove polling
    clear: () => clearInterval(pollingRef.current),
    callback,
    time
  }
}
