import React from 'react'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { selectComments } from '~selectors/widget_result'

import { DATE_TIME_FORMAT_API, DATE_TIME_FORMAT } from 'frontend/constants'

export const WidgetResultComments = (props) => {
  const { commentsId } = props
  const { t } = useTranslation()
  const comments = useSelector(selectComments(commentsId))

  return (
    <div className='widget-result-comments'>
      <label>{t('components:widget-result.information.comments')}</label>
      {comments.map((comment, index) => (
        <div key={index} className='widget-result-comments__content'>
          <div className='widget-result-comments__content--body'>{comment.body}</div>
          { comment.files.length > 0 &&
            comment.files.map((file, indx) => (
              <a key={indx} className='widget-result-comments__content--url' href={file.url}>{file.name}</a>
            )
            )}
          <div className='widget-result-comments__content--created-at'>
            {DateTime.fromFormat(comment.created_at, DATE_TIME_FORMAT_API).toFormat(DATE_TIME_FORMAT)}
          </div>
        </div>
      ))}
    </div>
  )
}
