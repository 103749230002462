import axios from './config'

export const removeRecipient = (params) => axios.post(`/widget/remove_recipient`, { recipient: params })

export const addRecipient = (params) => axios.post(`/widget/add_recipient`, { recipient: params })

export const fetchWaypoints = (apiKey, params) => {
  return axios.get(`/widget/get_waypoints/${apiKey}`, { params: { filter: { ...params } } })
}

export const fetchNotificationChannels = (apiKey, dispatchId) => {
  return axios.get(`/widget/notification_channels/${apiKey}`, { params: { filter: { 'dispatch': dispatchId } } })
}
