import React from 'react'

import { useTranslation } from 'react-i18next'

import Lightbox from '@beetrack/hp-lightbox-react'

const WidgetResultPod = (props) => {
  const { evaluationAnswers = [] } = props
  const { t } = useTranslation()

  const getValueToLightbox = (item) => {
    return item.split(',').map(p => { return { src: p, title: item.title } })
  }

  return (
    <div className='widget-result-pod'>
      <label className='widget-result-pod__title'>{t('components:widget-result.pod')}</label>
      {evaluationAnswers.map((item, index) =>
        <div key={index} className='widget-result-pod__content'>
          <div className='widget-result-pod__question'>
            {item.title}
          </div>
          {item.type === 'signature' && (
            <Lightbox
              additionalClassName='widget-result-pod__lightbox'
              items={[{ src: item.item, title: item.title }]}
              thumbnailWidth='110px' />)
          }
          {
            item.type === 'photo' && (
              <Lightbox
                additionalClassName='widget-result-pod__lightbox'
                items={getValueToLightbox(item.item)}
                thumbnailWidth='110px' />)
          }
          { item.type !== 'photo' && item.type !== 'signature' && (
            <div className='widget-result-pod__response'>
              {item.item}
            </div>)
          }
        </div>
      )}
    </div>
  )
}

export default WidgetResultPod
