import React from 'react'
import { Chip } from '@beetrack/hp-components'
import i18n from '~localization/index'

export const settingsSidebarGroups = [
  {
    title: () => i18n.t('layouts:settings.sidebar.account.title'),
    links: [
      {
        permissionRequired: 'accounts',
        title: () => i18n.t('layouts:settings.sidebar.account.initial_settings'),
        url: '/settings/account',
        useRouter: true
      },
      {
        permissionRequired: 'customer_portal',
        title: () => i18n.t('layouts:settings.sidebar.account.customer_portal'),
        url: '/settings/customer_portal',
        useRouter: false
      }
    ]
  },
  {
    title: () => i18n.t('layouts:settings.sidebar.users.title'),
    links: [
      {
        permissionRequired: 'users',
        title: ({ webUsersCount, dark }) => (
          <>
            {i18n.t('layouts:settings.sidebar.users.web_users')}
            <Chip text={webUsersCount} dark={dark} dense />
          </>
        ),
        url: '/settings/users',
        useRouter: false
      },
      {
        permissionRequired: 'truck_drivers',
        title: ({ mobileUsersCount, dark }) => (
          <>
            {i18n.t('layouts:settings.sidebar.users.mobile_users')}
            <Chip text={mobileUsersCount} dark={dark} dense />
          </>
        ),
        url: '/settings/truck_drivers',
        useRouter: false
      }
    ]
  },
  {
    title: () => i18n.t('layouts:settings.sidebar.manage.title'),
    links: [
      {
        permissionRequired: 'trucks',
        title: () => i18n.t('layouts:settings.sidebar.manage.trucks'),
        url: '/settings/trucks',
        useRouter: true
      },
      {
        permissionRequired: 'dispatch_sub_statuses',
        title: () => i18n.t('layouts:settings.sidebar.manage.substates'),
        url: '/settings/dispatch_sub_statuses',
        useRouter: true
      },
      {
        permissionRequired: 'forms',
        title: () => i18n.t('pages:proof-of-deliveries.title'),
        url: '/settings/forms',
        useRouter: true
      },
      {
        permissionRequired: 'places',
        title: () => i18n.t('layouts:settings.sidebar.manage.pickup'),
        url: '/settings/places',
        useRouter: true
      },
      {
        permissionRequired: 'group_categories',
        title: () => i18n.t('layouts:settings.sidebar.manage.groups'),
        url: '/settings/group_categories',
        useRouter: false
      },
      {
        permissionRequired: 'alerts',
        title: () => i18n.t('layouts:settings.sidebar.manage.events'),
        url: '/settings/events',
        useRouter: false
      },
      {
        permissionRequired: 'notifications',
        title: () => i18n.t('layouts:settings.sidebar.manage.notifications'),
        url: '/settings/notifications',
        useRouter: false
      }
    ]
  },
  {
    title: () => i18n.t('layouts:settings.sidebar.advanced.title'),
    links: [
      {
        permissionRequired: 'advanced_settings',
        title: () => i18n.t('layouts:settings.sidebar.advanced.setup'),
        url: '/settings/advanced',
        useRouter: false
      },
      {
        permissionRequired: 'webhooks',
        title: () => i18n.t('pages:webhooks.title'),
        url: '/settings/webhooks',
        useRouter: true
      },
      {
        permissionRequired: 'security',
        title: () => i18n.t('layouts:settings.sidebar.advanced.security'),
        url: '/settings/security',
        useRouter: false
      }
    ]
  },
  {
    title: () => i18n.t('layouts:settings.sidebar.widget.title'),
    links: [
      {
        permissionRequired: 'widget',
        title: () => i18n.t('layouts:settings.sidebar.widget.widget_web'),
        url: '/settings/widget',
        useRouter: true
      }
    ]
  }
]
