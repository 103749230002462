import { createReducer } from '@reduxjs/toolkit'
import * as subStatusesActions from '~actions/settings/subStatuses'
import { fetchInitialState, handleFetchReducer,
  handleFetchSelectOptions,
  handlePageFulfilled, pagedInitialState } from '~reducers/shared'

const INITIAL_STATE = {
  ...fetchInitialState,
  ...pagedInitialState,
  indexLoadingItems: [],
  subStatus: undefined,
  statusOptions: {
    options: [],
    loading: false
  },
  formatsOptions: {
    options: [],
    loading: false
  },
  modesOptions: {
    options: [],
    loading: false
  }
}

export const subStatusesReducer = createReducer(INITIAL_STATE, (builder) =>
  builder
    .addCase(subStatusesActions.deleteSubStatus.pending, (state, { meta: { arg: { deleteItems } } }) => {
      const loadingItemsIndex = deleteItems.map(({ index }) => index)
      state.indexLoadingItems = loadingItemsIndex
    })
    .addCase(subStatusesActions.deleteSubStatus.fulfilled, (state, action) => {
      state.indexLoadingItems = []
    })
    .addCase(subStatusesActions.deleteSubStatus.rejected, (state, action) => {
      state.indexLoadingItems = []
    })
    .addMatcher(...handleFetchReducer(subStatusesActions.fetchSubStatuses))
    .addMatcher(...handlePageFulfilled(subStatusesActions.fetchSubStatuses))
    .addMatcher(...handleFetchSelectOptions(subStatusesActions.fetchSubStatusesStatuses, 'statusOptions'))
    .addMatcher(...handleFetchSelectOptions(subStatusesActions.fetchSubStatusesFormats, 'formatsOptions'))
    .addMatcher(...handleFetchSelectOptions(subStatusesActions.fetchSubStatusesModes, 'modesOptions'))
    .addMatcher(...handleFetchReducer(subStatusesActions.createSubStatus))
    .addMatcher(...handleFetchReducer(subStatusesActions.getSubStatus))
    .addMatcher(...handleFetchReducer(subStatusesActions.updateSubStatus))
)

export default subStatusesReducer
