import React from 'react'

import { useTranslation } from 'react-i18next'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { Chip } from '@beetrack/hp-components'

import { addRecipient, removeRecipient } from '~actions/widget_result'

import { NOTIFICATION_TYPE } from 'frontend/constants'
import { Collapsible } from '~components/collapsible'
import { WidgetResultSubscriberCreator } from '.'

const WidgetResultFooterNotification = (props) => {
  const { t } = useTranslation()
  const { notificationContacts = [], beecode, withBorder } = props
  const dispatch = useDispatch()

  const handleSave = (values) => {
    const channelType = NOTIFICATION_TYPE[values.channel_type]
    const params = { beecode, 'channel_type': values.channel_type, [channelType]: values.name }
    return dispatch(addRecipient(params))
      .then(unwrapResult)
  }

  const handleDelete = (contact) => {
    const channelType = contact['phone'] ? 'notification_sms' : 'notification_email'
    const params = { beecode, 'channel_type': channelType, contact_id: contact.id }
    return dispatch(removeRecipient(params))
      .then(unwrapResult)
  }

  const getContact = (notificationContact) => {
    return [notificationContact['email'], notificationContact['phone']].map((contact, index) => (
      contact && <Chip
        key={index}
        additionalClassName='widget-result-footer-notification--chip'
        dense
        closeButton
        onClose={_ => handleDelete(notificationContact)}
        text={contact}
      />
    )).filter(value => value)
  }

  return (
    <Collapsible
      withBorder={withBorder}
      title={t('components:widget-result.notification.title')}>
      <div className='widget-result-footer-notification'>
        <WidgetResultSubscriberCreator onSubmit={handleSave} />
        <div className='widget-result-footer-notification__content-chip'>
          {notificationContacts.map((notificationContact) =>
            getContact(notificationContact)
          )}
        </div>
      </div>
    </Collapsible>
  )
}

export default WidgetResultFooterNotification
