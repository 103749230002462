import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { InnerTabItem, InnerTabs } from '@beetrack/hp-components'

import { selectNotificationChannels, selectOrder, selectOrderSelected,
  selectWidgetConfig, selectWidgetKey } from '~selectors/widget_result'
import { fetchNotificationChannels } from '~actions/widget_result'

import { WidgetResultInformation, WidgetResultOrder, WidgetResultPod, WidgetResultTracking,
  WidgetResultFooter, WidgetResultFooterNotification, WidgetResultMap,
  WidgetResultEmptyState } from '~components/widget_result'
import WidgetResultDescription from '~components/widget_result/widget_result_description'

const WidgetResult = () => {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(0)
  const orderSelected = useSelector(selectOrderSelected)
  const order = useSelector(selectOrder(orderSelected))
  const { permissions, logo_url: logo, description } = useSelector(selectWidgetConfig)
  const notificationChannels = useSelector(selectNotificationChannels)
  const key = useSelector(selectWidgetKey)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!order?.dispatch?.evaluation_answers.length > 0 && selectedTab === 2) setSelectedTab(0)
    const params = { key, dispatchId: order.dispatch?.id }
    permissions?.subscription && dispatch(fetchNotificationChannels(params))
  }, [orderSelected])

  const footer = () => {
    return (
      <div className='widget-result__footer'>
        {description && <WidgetResultDescription description={description} /> }
        {permissions?.subscription && notificationChannels &&
          <WidgetResultFooterNotification
            beecode={order?.beecode}
            notificationContacts={order?.notification_contacts}
            dispatchId={order.dispatch?.id}
            withBorder={description} />}
        <WidgetResultFooter />
      </div>
    )
  }

  const renderWithMap = () => {
    return (
      <div className='widget-result'>
        <div className='widget-result__data'>
          <div className='widget-result__content'>
            <div className='widget-result__logo'> <img src={logo} /></div>
            <WidgetResultOrder />
            <div className='widget-result__inner-tabs'>
              <InnerTabs
                key={orderSelected}
                defaultActiveTab={0}
                onChange={(value) => setSelectedTab(value.toIndex)}>
                {[
                  <InnerTabItem key='tab-1' label={t('pages:widget-result.tab-item-tracking')} />,
                  <InnerTabItem key='tab-2' label={t('pages:widget-result.tab-item-information')} />,
                  order?.dispatch?.evaluation_answers.length > 0 &&
                  <InnerTabItem key='tab-3' label={t('pages:widget-result.tab-item-pod')} />
                ].filter(Boolean)}
              </InnerTabs>
            </div>
            { selectedTab === 0 && <WidgetResultTracking idHistories={order.histories} /> }
            { selectedTab === 1 && <WidgetResultInformation order={order} /> }
            { selectedTab === 2 &&
              <WidgetResultPod evaluationAnswers={order?.dispatch?.evaluation_answers} /> }
          </div>
          {footer()}
        </div>
        <WidgetResultMap
          addressId={order.address}
          dispatchId={order.dispatch?.id}
          statusId={order.dispatch?.status_id}
        />
      </div>
    )
  }

  const renderWithoutMap = () => {
    return (
      <div className='widget-result--without-map'>
        <div>
          <div className='widget-result__logo'> <img src={logo} /></div>
          <div className='widget-result__data--without-map'>
            <div className='widget-result__content--without-map'>
              <WidgetResultOrder />
              <WidgetResultTracking idHistories={order.histories} />
            </div>
            <div className='widget-result__second-content'>
              <WidgetResultInformation order={order} />
              {order?.dispatch?.evaluation_answers.length > 0 &&
                <WidgetResultPod evaluationAnswers={order.dispatch?.evaluation_answers} /> }
            </div>
          </div>
        </div>
        {footer()}
      </div>
    )
  }

  if (!orderSelected) return (<WidgetResultEmptyState />)

  if (orderSelected) {
    return (
      <>
        { permissions?.map ? renderWithMap() : renderWithoutMap()}
      </>
    )
  }
}

export default WidgetResult
