import { getEntity, ENTITY } from '~selectors/entities'

const proofOfDeliveriesStore = (state) => state.settings.proofOfDeliveries || {}

// eslint-disable-next-line max-lines-per-function
export const selectActivity = (presentationId, activityId) => (state) => {
  const getActivities = (id) => getEntity(ENTITY.proofOfDeliveriesActivity, id)(state)
  const getChoiceActivities = (id) => getEntity(ENTITY.choiceActivity, id)(state)
  const getChoice = (id) => getEntity(ENTITY.choice, id)(state)
  const getActivityType = (id) => getEntity(ENTITY.activityType, id)(state)

  let activity = getActivities(activityId)
  const choiceActivity = getChoiceActivities(activityId)

  if (!activity && !choiceActivity) return

  if (choiceActivity) {
    activity = {
      ...choiceActivity,
      choice_cast: choiceActivity.cast,
      cast: 'choice',
      choices_attributes: (choiceActivity?.choices || []).map(getChoice).filter(Boolean)
    }
  }

  const choices = activity?.choices_attributes || []

  return {
    ...activity,
    presentationId,
    activityType: getActivityType(activity.cast)?.name || activity.cast,
    chips: activity?.dispatch_sub_statuses || [],
    accordionId: `${presentationId}-${activity.id}`,
    dispatchStatusIds: activity.dispatch_status_id,
    modelName: ENTITY.proofOfDeliveriesActivity,
    initialValues: {
      id: activity.id,
      name: activity.name,
      cast: activity.cast,
      // position: activity?.position, // This line is commented until the backend has the service ready
      dispatch_sub_status_id: activity.dispatch_sub_statuses?.map(dispatchSubStatus => `${dispatchSubStatus.id}`),
      contactless: activity.contactless,
      required: activity.required,
      character_limit: activity.character_limit,
      multiple: activity.multiple,
      autocomplete: activity.autocomplete,
      choices_attributes: [
        ...choices.map(choice => ({
          id: choice.id,
          name: choice.name
        })),
        ...choices.length < 2 ? Array(2 - choices.length).fill({}) : []
      ]
    }
  }
}

// eslint-disable-next-line max-lines-per-function
export const selectProofOfDeliveries = (state) => {
  const {
    fetchProofOfDeliveries: { data, status },
    isSaving,
    isDeleting,
    isCreateDefaultActivity,
    isMovingActivity
  } = proofOfDeliveriesStore(state)
  const getPresentation = (id) => getEntity(ENTITY.proofOfDeliveriesPresentation, id)(state)
  const proofOfDeliveries = data?.map(id => {
    const presentation = getPresentation(id)
    const initialValues = {
      id: presentation?.id,
      name: presentation.name || '',
      dispatch_status_id: presentation?.dispatch_statuses.map((statusObj) => `${statusObj.id}`) || [],
      dispatch_sub_status_id: presentation?.dispatch_sub_statuses.map((subStatusObj) => `${subStatusObj.id}`) || [],
      group_ids: presentation?.groups.map((groupObj) => `${groupObj.id}`) || []
    }

    const activities = (presentation?.activities || []).map(
      activityId => selectActivity(presentation.id, activityId)(state)
    ).filter(Boolean)

    return {
      ...presentation,
      modelName: ENTITY.proofOfDeliveriesPresentation,
      dispatchStatusIds: presentation?.dispatch_statuses?.map(dispatchSubStatus => `${dispatchSubStatus.id}`),
      dispatchSubStatusOptions: presentation?.dispatch_sub_statuses.map(
        dispatchSubStatus => ({ label: dispatchSubStatus.name, value: `${dispatchSubStatus.id}` })
      ),
      activities: [
        ...activities,
        {
          isNew: true,
          accordionId: `${presentation.id}-new-activity`,
          initialValues: {
            required: false,
            choices_attributes: [{}, {}]
          }
        }
      ],
      initialValues,
      chips: [
        ...presentation.dispatch_statuses,
        ...presentation.dispatch_sub_statuses.map(dispatchSubStatus => ({
          ...dispatchSubStatus,
          isSubStatus: true
        }))
      ]
    }
  }) || []
  return {
    activityModelName: ENTITY.proofOfDeliveriesActivity,
    presentationModelName: ENTITY.proofOfDeliveriesPresentation,
    proofOfDeliveries,
    status,
    isSaving,
    isCreateDefaultActivity,
    isDeleting,
    isMovingActivity
  }
}

export const selectIsInfoBox = (state) => {
  const { fetchProofOfDeliveries: { data } } = proofOfDeliveriesStore(state)
  let isShowInfoBox = false
  const predeterminatesIdActivities = [2, 3, 4]
  if (data?.length === predeterminatesIdActivities.length) {
    let listStatuses = data?.map((proof) => {
      const proofOfDeliveries = getEntity(ENTITY.proofOfDeliveriesPresentation, proof)(state)
      if (proofOfDeliveries.dispatch_sub_statuses.length === 0 && proofOfDeliveries.activities.length === 0) {
        return proofOfDeliveries?.dispatch_statuses.map(value => value.id)
      }
      return null
    })
    listStatuses = [].concat.apply([], listStatuses).sort()
    if (listStatuses?.length === predeterminatesIdActivities.length &&
        listStatuses.every((value, index) => value === predeterminatesIdActivities[index])) {
      isShowInfoBox = true
    }
  }
  return isShowInfoBox
}

export const selectDispatchStatus = (state) => {
  const { fetchStatus: { data, status } } = proofOfDeliveriesStore(state)
  const getDispatchStatus = (id) => getEntity(ENTITY.status, id)(state)
  const dispatchStatus = data?.map(id => {
    const status = getDispatchStatus(id)
    return {
      label: status.name,
      value: `${status.id}`
    }
  }) || []
  return {
    dispatchStatus,
    statusDispatchStatus: status
  }
}

export const selectActivityTypesOptions = (state) => {
  const { fetchActivityTypes: { data: activityTypesId } } = proofOfDeliveriesStore(state)
  const getActivityType = (id) => getEntity(ENTITY.activityType, id)(state)

  return (activityTypesId?.map(getActivityType) || []).map(activityType => ({
    label: activityType.name,
    value: activityType.id
  }))
}

export const selectActivityFormDispatchSubStatusOptions = (state) => {
  const {
    fetchActivityDispatchSubStatus: {
      data: { ids: dispatchSubStatusIds, meta } = {}
    }
  } = proofOfDeliveriesStore(state)
  const getDispatchSubStatus = (id) => getEntity(ENTITY.subStatus, id)(state)

  return {
    dispatchSubStatusOptions: (currentValueIds = []) =>
      ([...new Set([...currentValueIds, ...(dispatchSubStatusIds || [])])].map(getDispatchSubStatus))
        .filter(Boolean).map(dispatchSubStatus => ({
          label: dispatchSubStatus.name,
          value: `${dispatchSubStatus.id}`
        })),
    dispatchSubStatusHasMorePages: () => !meta || (meta?.current_page < meta?.total_pages)
  }
}

export const selectPresentationFormDispatchSubStatusOptions = (state) => {
  const {
    fetchPresentationDispatchSubStatus: {
      data: { ids: dispatchSubStatusIds, meta } = {},
      status
    } } = proofOfDeliveriesStore(state)
  const getDispatchSubStatus = (id) => getEntity(ENTITY.subStatus, id)(state)
  return {
    dispatchSubStatusOptions: (presentationFormValues = {}) => {
      const {
        dispatch_sub_status_id: dispatchSubStatusId,
        dispatch_status_id: dispatchStatusId
      } = presentationFormValues
      if (!dispatchStatusId?.length) return []
      const idSubStatus = [...new Set([...dispatchSubStatusId || [], ...(dispatchSubStatusIds || [])])]
        .map(getDispatchSubStatus)
        .filter(Boolean)
      return idSubStatus.map(dispatchSubStatus => ({
        label: dispatchSubStatus.name,
        value: `${dispatchSubStatus.id}`
      }))
    },
    dispatchSubStatusHasMorePages: () => !meta || (meta?.current_page < meta?.total_pages),
    dispatchSubStatus: (idsStatus, idsSubStatus = []) => (
      idsSubStatus.map(getDispatchSubStatus)
        .filter(subStatus => idsStatus.includes(subStatus?.dispatch_status_id?.toString()))
        .map((subStatusObj) => `${subStatusObj.id}`)),
    status,
    dispatchSubStatusIds
  }
}

export const selectGroupsOptions = (state) => {
  const {
    fetchPresentationGroups: {
      data: { options: groupsOptions, meta, optionsGroupIds } = {}, status } } = proofOfDeliveriesStore(state)
  const getGroup = (id) => getEntity(ENTITY.group, id)(state)
  const getGroupCategory = (id) => getEntity(ENTITY.groupCategory, id)(state)
  return {
    dispatchGroupsHasMorePages: () => !meta || (meta?.current_page < meta?.total_pages),
    dispatchGroupsOptions: (presentationFormValues = {}) => {
      const { group_ids: groupIds } = presentationFormValues
      const notLoadedGroupsIds = groupIds?.filter(id => !optionsGroupIds?.includes(id)) || []
      const notLoadedGroups = notLoadedGroupsIds.map(getGroup).map(group => ({
        ...group,
        groupCategory: getGroupCategory(group?.group_category?.id)
      }))

      const notLoadedOptions = notLoadedGroups.map(group => ({
        label: group.name,
        value: `${group.id}`,
        isHidden: true,
        sortStr: `${group.groupCategory?.name}-${group.name}`
      }))

      // Add not loaded options with isHidden flag
      const mergedOptions = [
        ...notLoadedOptions,
        ...groupsOptions || []
      ]
      // Sort options to display chips like the same order if they were loaded from backend
      mergedOptions.sort(
        (optionA, optionB) => (optionA.sortStr || optionA.label).localeCompare(optionB.sortStr || optionB.label)
      )

      return mergedOptions
    },
    status
  }
}
