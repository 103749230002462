import React from 'react'

export const WidgetResultDescription = (props) => {
  const { description } = props

  return (
    <div className='widget-result-description'>
      {description}
    </div>
  )
}

export default WidgetResultDescription
