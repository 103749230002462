import { useLocation, useNavigate } from 'react-router-dom'

import { URLToParams, paramsToURL } from '~utils'

export const useURLParams = (options = {}) => {
  const { deletePage } = options
  const location = useLocation()
  const navigate = useNavigate()

  const setURLParams = (params_, replace = true) => {
    const params = { ...params_ }

    if (deletePage) delete params.page

    navigate({
      location: location.pathname,
      search: '?' + paramsToURL(params)
    }, { replace })
  }

  return [URLToParams(location.search), setURLParams]
}
