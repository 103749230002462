import { MANAGED_DISPATCH_STATUSES } from 'frontend/constants'
import { hideEmail, hidePhone } from '~utils/strings'
import { ENTITY, getEntity } from './entities'

export const selectWidgetConfig = (state) => state.widgetResult?.widgetConfig

export const selectWidgetKey = (state) => state.widgetResult?.widgetKey

export const selectAccount = id => state => state.entities?.account?.[id] || {}

export const selectOrderSelected = (state) => state.widgetResult?.orderSelected

export const selectWidgetResultData = (state) => state.widgetResult

export const selectContact = (contactId) => (state) => state.entities?.contact?.[contactId] || {}

export const selectWaypoint = (state) => {
  if (!state.entities.waypoint) return []
  const { waypoint } = state.entities
  const { idsWaypoint = [] } = state.widgetResult

  return idsWaypoint.map(id => {
    return { 'id': waypoint[id].id, 'lat': waypoint[id].latitude, lng: waypoint[id].longitude }
  })
}

export const selectAddress = (addressId) => (state) => state.entities?.address?.[addressId] || {}

export const selectOrders = (state) => {
  const { dispatchGuide } = state.widgetResult
  const getDispatchGuide = (id) => getEntity(ENTITY.distpatchGuide, id)(state)
  return dispatchGuide.map(getDispatchGuide).map(
    dispatchGuide => ({ value: dispatchGuide.id, label: dispatchGuide.code })
  )
}

export const selectOrder = (orderSelected) => (state) => {
  if (orderSelected === undefined) return {}
  const { dispatch_guide: guide } = state.entities
  const order = { ...guide[orderSelected] }

  return {
    ...order,
    dispatch: (!order.dispatch) ? undefined : order.dispatch,
    notification_contacts: order.notification_contacts.map(contact => {
      return {
        id: contact.id,
        phone: hidePhone(contact.phone),
        email: hideEmail(contact.email)
      }
    }),
    isManaged: MANAGED_DISPATCH_STATUSES.includes(order?.dispatch?.status_id)
  }
}

export const selectHistories = (idHistories) => (state) => {
  if (!idHistories) return []
  const { history, dispatch_sub_status: dispatchSubStatus = {} } = state.entities
  const histories = idHistories.map(idHistory => history[idHistory])

  return histories.map(item => {
    return {
      ...item,
      dispatchSubStatus: dispatchSubStatus[item?.dispatch_sub_status] || {}
    }
  }).sort((a, b) => b.id - a.id)
}

export const selectItems = (itemsId) => (state) => {
  if (!itemsId) return []
  const { guide_item: guideItems } = state.entities
  return itemsId.map(itemId => guideItems[itemId])
}

export const selectComments = (commentsId) => (state) => {
  if (!commentsId) return []
  const { comment, file_resource: files } = state.entities

  return commentsId.map(commentId => {
    return {
      ...comment[commentId],
      files: comment[commentId].file_resources.map(file => files[file])
    }
  })
}

export const selectNotificationChannels = (state) => {
  const { notification_channel: notificationChannel } = state.entities
  if (!notificationChannel) return

  return Object.values(notificationChannel).map(notification => {
    return {
      value: notification.id, label: notification.name
    }
  })
}
