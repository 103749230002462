import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Field } from 'formik'
import { Select } from '@beetrack/hp-components'

import { getDark } from '~selectors/layout'

export const FormikSelect = (props) => {
  const { name, value, onChange, disabled, infinitePagination, ...otherProps } = props
  const dark = useSelector(getDark)

  return (
    <Field name={name}>
      {({ form, field, meta }) => {
        const { setFieldValue } = form
        const { error, touched } = meta
        const fieldDisabled = disabled || form.isSubmitting
        const hasInteraction = !fieldDisabled && (touched || form.submitCount > 0)

        const handleChange = (selected) => {
          if (Array.isArray(selected)) {
            setFieldValue(name, selected.map(option => option.value))
          } else {
            setFieldValue(name, selected?.value)
          }
          onChange(selected)
        }

        return (
          <Select
            dark={dark}
            {...otherProps}
            {...infinitePagination && {
              pageSize: 25,
              remoteSearch: true,
              lazyLoad: true
            }}
            value={value || field?.value}
            onChange={handleChange}
            error={hasInteraction && !!error}
            helperText={(hasInteraction && error) || ''}
            disabled={fieldDisabled}
          />
        )
      }}
    </Field>
  )
}

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

FormikSelect.defaultProps = {
  onChange: () => {}
}

export default FormikSelect
