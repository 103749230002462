import React from 'react'
import PropTypes from 'prop-types'

import { Map, NoDataState } from '@beetrack/hp-components'

import { GOOGLE_MAP_STYLES, MAP_CENTER, MAP_TILE_CONFIG } from 'frontend/constants/map'

const PageMap = props => {
  const { id, additionalClassName, emptyStateProps, loading = false, showInfoWindow, children, kind, ...others } = props

  const hasChildren = children => Array.isArray(children)
    ? children.length > 0
    : Object.keys(children).length > 0

  return (
    <div className={additionalClassName}>
      {(!loading && !hasChildren(children)) && <NoDataState overlay {...emptyStateProps} />}
      <Map
        id={id}
        styles={GOOGLE_MAP_STYLES}
        kind={window.Constants?.MAP() || kind}
        forceRenderAfterMount
        {...others}
        showInfoWindow={showInfoWindow}
      >
        {children}
      </Map>
    </div>
  )
}

PageMap.defaultProps = {
  kind: 'leaflet',
  tile: MAP_TILE_CONFIG,
  center: MAP_CENTER,
  zoom: 14,
  maxZoom: 18,
  maxFitBoundsZoom: 14,
  cluster: false,
  invalidateSize: true
}

PageMap.propTypes = {
  kind: PropTypes.string,
  tile: PropTypes.object,
  zoom: PropTypes.number,
  cluster: PropTypes.bool,
  children: PropTypes.node,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  maxZoom: PropTypes.number,
  invalidateSize: PropTypes.bool
}

export default PageMap
