import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { hpUtils } from '@beetrack/hp-components'

export const Stepper = ({ children, additionalClassName }) => {
  return (
    <div className={hpUtils.getBEMClasses('stepper', { }, additionalClassName)}>
      {children && Children.map(children, (child, childIndex) => {
        return React.cloneElement(child, {
          key: childIndex,
          selfIndex: childIndex
        })
      })}
    </div>
  )
}

Stepper.propTypes = {
  children: PropTypes.node.isRequired
}

export default Stepper
