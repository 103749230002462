import axios from '../config'
import { mapTruckDriversIntoSelectOptions, mapTruckResponseIntoFormData } from '~utils/form_parsers/trucks'
import { getGroupCategoriesOptions } from '~utils/mappers/settings/trucks'

export const fetchTrucks = (params = {}) => {
  return axios.get(`/account/trucks`, {
    params
  })
}

export const deleteTruck = (id) =>
  axios.delete(`/settings/trucks/${id}.json`).catch((error) => {
    throw error
  })

export const fetchTruckGroups = () =>
  axios
    .get('/account/group_categories?groupable_type=Truck&include_groups=true&visible=true&no_paginate=true')
    .then(getGroupCategoriesOptions)
    .catch((error) => {
      throw error
    })

export const fetchTruckDrivers = () =>
  axios
    .get('/account/truck_drivers?truck_driver_without_truck=true')
    .then(mapTruckDriversIntoSelectOptions)
    .catch((error) => {
      throw error
    })

export const getTruck = (truckId) =>
  axios.get(`/account/trucks/${truckId}`)
    .then(mapTruckResponseIntoFormData)
    .catch((error) => {
      throw error
    })

export const createTruck = (truck) => {
  delete truck.truck_driver
  return axios.post('/settings/trucks.json', { truck })
}

export const updateTruck = (truckId, truck) => {
  delete truck.truck_driver
  return axios.put(`/settings/trucks/${truckId}.json`, { truck })
}
