import React from 'react'
import { useSelector } from 'react-redux'
import { hpUtils } from '@beetrack/hp-components'

import { getDark } from '~selectors/layout'

export const PageExplanation = ({ title, description }) => {
  const dark = useSelector(getDark)

  return (
    <div className='page-explanation'>
      <div className={hpUtils.getBEMClasses('page-explanation__title', { dark })}>
        {title}
      </div>
      <div className={hpUtils.getBEMClasses('page-explanation__description', { dark })}>
        {description}
      </div>
    </div>
  )
}

export default PageExplanation
