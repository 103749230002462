import normalizeJsonAPI from 'jsonapi-normalizer'
import { DateTime } from 'luxon'
import { capitalize } from '~utils'

// Ej. from '2000-01-01T10:30:00.000Z' to '10:30'
const formatDateTimeIgnoringTheTimezone = (datetime) => (
  DateTime.fromISO(datetime).setZone('utc').toLocaleString(DateTime.TIME_24_SIMPLE)
)

export const mapTruckResponseIntoFormData = (response) => {
  const { result, entities } = normalizeJsonAPI(response.data, true)
  const id = result.truck[0]
  const truck = entities.truck[id]
  const truckContext = truck.truck_context ? entities.truck_context[truck.truck_context] : {}

  const { identifier, groups, vehicle_type: vehicleType, capacity, truck_driver: truckDriver } = truck
  const truckDriverData = entities.truck_driver ? Object.values(entities.truck_driver)[0] : undefined

  return {
    identifier,
    group_ids: groups || [],
    vehicle_type: vehicleType,
    truck_driver_id: truckDriver || '',
    capacity,
    truck_context_attributes: {
      ...truckContext,
      start_time: truckContext.start_time && formatDateTimeIgnoringTheTimezone(truckContext.start_time),
      end_time: truckContext.end_time && formatDateTimeIgnoringTheTimezone(truckContext.end_time)
    },
    truck_driver: truckDriverData ? [{ label: truckDriverData?.name, value: truckDriverData?.id }] : []
  }
}

export const mapTruckDriversIntoSelectOptions = (response) => {
  const { result, entities } = normalizeJsonAPI(response.data, true)

  return (result.truck_driver || []).map(truckDriverId => {
    const truckDrivers = entities.truck_driver[truckDriverId]

    return {
      label: truckDrivers.name,
      value: truckDriverId
    }
  })
}

export const parseTruckRequest = (data) => {
  const trucks = data
  return trucks.map(truck => {
    return { label: truck.label, options: truck.options }
  })
}

export const parseTruckErrors = (errorResponse = {}) => {
  let errors = {}
  Object.entries(errorResponse).forEach(([field, text]) => {
    field = field.replace('truck_context', 'truck_context_attributes')
    errors = { ...errors, [field]: capitalize(text[0], false) }
  })
  return errors
}
