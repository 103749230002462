export const entitiesStore = (state) => state.entities || {}

export const getEntity = (entityName, id) => (state) =>
  entitiesStore(state)[entityName]?.[id]
export const getEntityWithoutId = (entityName) => (state) =>
  entitiesStore(state)[entityName]

export const ENTITY = {
  truck: 'truck',
  truckDriver: 'truck_driver',
  account: 'account',
  webhook: 'outbound_configuration',
  webhookEvent: 'outbound_configuration_resource',
  webhookResource: 'outbound_configuration_resources',
  route: 'route',
  dispatch: 'dispatch',
  distpatchGuide: 'dispatch_guide',
  contact: 'contact',
  address: 'address',
  customField: 'custom_field',
  customFieldTypes: 'custom_field_type',
  customFieldTypesValues: 'custom_field_type_value',
  guideItem: 'guide_item',
  place: 'place',
  dispatchSubStatus: 'dispatch_sub_status',
  proofOfDeliveriesActivity: 'activity',
  choiceActivity: 'choice_activity',
  choice: 'choice',
  activityType: 'activity_type',
  proofOfDeliveriesEvaluation: 'evaluation',
  proofOfDeliveriesPresentation: 'presentation',
  subStatus: 'dispatch_sub_status',
  status: 'dispatch_status',
  widget: 'widget',
  widgetStatistic: 'widget_statistic',
  group: 'group',
  groupCategory: 'group_category'
}
