export const getProperty = (object, dotNotation) => {
  const keys = dotNotation.split('.')
  let property = object
  for (let i = 0; i < keys.length; i++) {
    property = property?.[keys[i]]
  }
  return property
}

export const isObject = obj => obj && typeof obj === 'object'

export const isObjectEmpty = obj => isObject(obj) && Object.keys(obj).length === 0

export const getSubObject = (fullState, keyName, subKeyName) => {
  if (keyName && !fullState[keyName]) fullState[keyName] = {}

  if (subKeyName && fullState[keyName]) return fullState[keyName]?.[subKeyName]

  return keyName ? fullState[keyName] : fullState
}

export const deleteObjectEmptyValues = object_ => {
  const object = { ...object_ }

  Object.keys(object).forEach(key => {
    const objectKey = object[key]

    if (objectKey === '' || objectKey === null || objectKey === undefined) {
      delete object[key]
    }
  })

  return object
}
