import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Table } from '@beetrack/hp-components'

import { useURLParams } from '~hooks'

import { getDark } from '~selectors/layout'

import { isObjectEmpty } from '~utils'
import { EVENT_TYPES } from '~constants'

const HONEYPOT_LOADING_MAP = {
  paging: EVENT_TYPES.PAGING,
  sorting: EVENT_TYPES.SORTING
}

const PageTable = props => {
  const dark = useSelector(getDark)
  const [{ sort, filter }] = useURLParams()
  const {
    actions,
    ids,
    columns,
    pagination,
    loading,
    updateData,
    setVisiblePages,
    getItemFromData,
    ...restProps } = props

  const getSort = () => {
    if (!sort || isObjectEmpty(sort)) return
    const [name, direction] = Object.entries(sort)[0]
    return { name, direction }
  }

  const handleFetch = (actionType, params) => {
    const sort = {}
    if (params.sort && Object.keys(params.sort).length > 0) {
      sort[params.sort.name] = params.sort.direction
    }

    const fetchParams = {
      ...params,
      sort
    }
    return updateData(HONEYPOT_LOADING_MAP[actionType], fetchParams)
  }

  return (
    <Table
      actions={actions}
      columns={columns}
      data={Object.values(ids)}
      dark={dark}
      fetchData={handleFetch}
      getItemFromData={index => getItemFromData(ids[index])}
      hasInfinitePagination
      isItemLoaded={index => Boolean(ids?.[index])}
      loading={loading.isFirstTime || loading.isFiltering || loading.isSorting}
      onVisiblePageChange={setVisiblePages}
      pageSize={pagination.pageSize}
      sort={getSort(sort)}
      filter={filter}
      totalItems={pagination.totalItems}
      {...restProps}
    />
  )
}

PageTable.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  pagination: PropTypes.object,
  updateData: PropTypes.func,
  setVisiblePages: PropTypes.func,
  getItemFromData: PropTypes.func,
  indexLoadingItems: PropTypes.array,
  ids: PropTypes.object,
  setTableParams: PropTypes.func
}

export default PageTable
