import axios from '../config'

export const createDefaultSections = () => {
  return axios.post('/settings/evaluations/create_default')
}

export const fetchProofOfDelivery = () => {
  return axios.get('/account/evaluation')
}

export const createNewPresentation = (data) => {
  return axios.post('/settings/evaluations/presentations', data)
}

export const updatePresentation = (id, data) => {
  return axios.put(`/settings/evaluations/presentations/${id}`, data)
}

export const deletePresentation = (id) => {
  return axios.delete(`/settings/evaluations/presentations/${id}`)
}

export const fetchActivityTypes = () => {
  return axios.get('/account/evaluation/activity_types')
}

export const fetchDispatchStatuses = () => {
  return axios.get('/account/dispatch_statuses?management_status_only=true')
}

export const fetchDispatchSubStatuses = ({ ...params }) => {
  return axios.get('/account/dispatch_sub_statuses', {
    params: { ...params, filter: { include_deleted: false, management_sub_statuses_only: true, ...params?.filter } }
  })
}

export const createActivity = (presentationId, data) => {
  return axios.post(`/settings/evaluations/presentations/${presentationId}/activities`, data)
}

export const updateActivity = (presentationId, activityId, data) => {
  return axios.put(`/settings/evaluations/presentations/${presentationId}/activities/${activityId}`, data)
}

export const deleteActivity = (presentationId, activityId) => {
  return axios.delete(`/settings/evaluations/presentations/${presentationId}/activities/${activityId}`)
}

export const createDefaultActivities = () => {
  return axios.post(`/settings/evaluations/presentations/activities/create_default`)
}

export const calculatePositionActivity = (presentationId, activityId, data) => {
  return axios.put(
    `/settings/evaluations/presentations/${presentationId}/activities/${activityId}/recalculate_position`,
    data
  )
}

export const fetchGroups = ({ ...params }) => {
  return axios.get('/account/groups?groupable_type=DispatchGuide', {
    params
  })
}
