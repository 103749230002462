import { createReducer } from '@reduxjs/toolkit'
import { getCurrentUser, toggleSidebar, openSnackbar, closeSnackbar, toggleDark } from '~actions/layout'

const INITIAL_STATE = {
  currentUser: null,
  isLoading: false,
  isOpen: false,
  language: null,
  // eslint-disable-next-line no-undef
  theme: localStorage.getItem('theme') || '',
  snackbarInfo: {
    isOpen: false,
    message: '',
    type: ''
  }
}

export const layoutReducer = createReducer(INITIAL_STATE, {
  [getCurrentUser.pending]: (state, action) => {
    state.isLoading = true
  },
  [getCurrentUser.fulfilled]: (state, action) => {
    state.currentUser = action.payload
    state.language = action.payload.language
    state.theme = action.payload.user.theme
    state.isLoading = false
    // eslint-disable-next-line no-undef
    localStorage.setItem('theme', state.theme)
  },
  [getCurrentUser.rejected]: (state, action) => {
    state.currentUser = null
    state.isLoading = false
  },
  [toggleSidebar]: (state, action) => {
    state.isOpen = !state.isOpen
  },
  [openSnackbar]: (state, { payload }) => {
    state.snackbarInfo = {
      ...INITIAL_STATE.snackbarInfo,
      ...payload,
      isOpen: true
    }
  },
  [closeSnackbar]: (state, action) => {
    state.snackbarInfo = {
      ...INITIAL_STATE.snackbarInfo,
      type: state.snackbarInfo.type
    }
  },
  [toggleDark]: (state, action) => {
    if (state.theme === 'dark') {
      state.theme = 'light'
    } else {
      state.theme = 'dark'
    }
    // eslint-disable-next-line no-undef
    localStorage.setItem('theme', state.theme)
  }
})

export default layoutReducer
