import React from 'react'
import { settingsSidebarGroups } from './settings_sidebar_groups'

export const SettingsSidebarLoading = () => {
  return settingsSidebarGroups.map((group, groupIndex) => {
    return (
      <React.Fragment key={groupIndex}>
        <div className='with-settings-sidebar__skeleton--header'>
          <div className='hp-skeleton-loader hp-skeleton-loader--text' />
        </div>
        {group.links.map((_, linkIndex) => (
          <div key={`${groupIndex}-${linkIndex}`} className='with-settings-sidebar__skeleton--item'>
            <div className='hp-skeleton-loader hp-skeleton-loader--text' />
          </div>
        ))}
      </React.Fragment >
    )
  })
}

export default SettingsSidebarLoading
