import { settingsSidebarGroups } from '~components/lastmile_settings_sidebar/settings_sidebar_groups'

const layoutStore = (state) => state.layout || {}

export const getDark = (state) => layoutStore(state).theme === 'dark'

export const getSnackbarInfo = (state) => layoutStore(state).snackbarInfo

export const getUserInfo = (state) => {
  const { currentUser, isLoading } = layoutStore(state)
  const dark = getDark(state)
  return {
    dark,
    isLoading,
    userEmail: currentUser?.user?.email || '',
    permissions: currentUser?.permissions || {},
    hasOndemand: currentUser?.hasOndemand
  }
}

export const getSidebarData = (sidebarItems = []) => (state) => {
  const { isLoading, currentUser, isOpen } = layoutStore(state)
  const dark = getDark(state)

  const allowedItems = isLoading
    ? Array(10).fill().map((_, key) => ({ key, icon: '', content: '', to: '' }))
    : sidebarItems.filter(item => currentUser?.permissions[item.key])
      .map(item => ({
        ...item,
        content: typeof item.content === 'function' ? item.content() : item.content
      }))

  const firstLoading = isLoading && currentUser === null

  return {
    dark,
    isOpen,
    isLoading,
    firstLoading,
    allowedItems
  }
}

export const getTopbarData = (state) => {
  const { isLoading, currentUser } = layoutStore(state)
  const dark = getDark(state)

  const accountPicture = currentUser?.accountPicture

  const products = currentUser?.products

  const accounts = currentUser?.accounts

  const username = currentUser?.user && (
    currentUser.user.username ||
    currentUser.user.name ||
    currentUser.user.email
  )

  const avatar = currentUser?.user?.profile_picture

  const lastReports = currentUser?.lastReports || []

  const firstLoading = isLoading && currentUser === null

  return {
    dark,
    isLoading,
    firstLoading,
    accountPicture,
    products,
    accounts,
    username,
    avatar,
    lastReports
  }
}

export const getSettingsSidebarData = (state) => {
  const { isLoading, currentUser } = layoutStore(state)
  const dark = getDark(state)

  const settingsPermissions = currentUser?.settings_permissions || {}

  const titleParams = {
    mobileUsersCount: currentUser?.active_mobile_users_count || 0,
    webUsersCount: currentUser?.active_users_count || 0,
    dark
  }

  const groups = settingsSidebarGroups.map(group => ({
    ...group,
    title: typeof group.title === 'function' ? group.title() : group.title,
    links: (group?.links
      .filter(groupLink => settingsPermissions[groupLink.permissionRequired]) || [])
      .map(groupLink => ({
        ...groupLink,
        title: typeof groupLink.title === 'function' ? groupLink.title(titleParams) : groupLink.title
      }))
  })).filter(group => group.links.length > 0)

  const hasSettingsPermission = currentUser?.user?.binary_authorization?.settings

  return {
    dark,
    isLoading,
    groups,
    hasSettingsPermission
  }
}

export const selectSubStatusesFormPermissions = (state) => {
  const { currentUser } = layoutStore(state)

  return {
    showReverseLogistics: currentUser?.showReverseLogistics || false,
    showMode: currentUser?.showMode || false,
    enableLocks: currentUser?.enableLocks || false
  }
}

export const hasSettingsModulePermission = moduleName => state => {
  if (!moduleName) return true

  const { currentUser } = layoutStore(state)
  const settingsPermissions = currentUser?.settings_permissions || {}

  return settingsPermissions[moduleName]
}

export const getCurrentUserId = (state) => {
  const { currentUser } = layoutStore(state)
  return currentUser?.user?.account
}

export const getCurrentUser = (state) => layoutStore(state).currentUser?.user

export const getAccountPicture = (state) => layoutStore(state).currentUser?.accountPicture
export const getAccountPlanningMode = (state) => layoutStore(state).currentUser?.planningMode
