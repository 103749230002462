export const capitalize = (word = '', lowercaseFirst = true) => {
  const parsedWork = lowercaseFirst ? word.toLowerCase() : word
  return `${parsedWork.charAt(0).toUpperCase()}${parsedWork.slice(1)}`
}

export const snakeToCamelCase = (snakeName = '') =>
  snakeName
    .split('_')
    .map((word, index) => (index > 0 ? capitalize(word) : word.toLowerCase()))
    .join('')

export const objectKeysToCamelCase = (objectWithSnakeCaseKeys = {}) => {
  const objectWithCamelCaseKeys = {}
  const keys = Object.keys(objectWithSnakeCaseKeys)
  keys.forEach((snakeKey) => {
    objectWithCamelCaseKeys[snakeToCamelCase(snakeKey)] =
      objectWithSnakeCaseKeys[snakeKey]
  })
  return objectWithCamelCaseKeys
}

export const hideEmail = (email) => {
  if (!email) return
  const split = email.split('@')
  return split[0].slice(0, 3).padEnd(split[0].length, '*') + '@' + split[1].slice(0, 3).padEnd(split[1].length, '*')
}

export const hidePhone = (phone) => {
  if (!phone) return
  return phone.slice(0, 6).padEnd(phone.length, '*')
}
