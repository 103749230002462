import React from 'react'

import { useTranslation } from 'react-i18next'

import { URL_LOGO } from '~constants/index'

const WidgetResultFooter = () => {
  const { t } = useTranslation()

  return (
    <div className='widget-result-footer'>
      <label>{t('components:widget-result.footer.label')}</label>
      <img src={URL_LOGO} />
    </div>
  )
}

export default WidgetResultFooter
