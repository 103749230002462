import deepMerge from 'deepmerge'

import { useURLParams } from '.'

import { mergeArray } from '~utils'

import { EVENT_TYPES } from '~constants'

export const useFetchWithURL = (fetchFunction, defaultParams = {}) => {
  const [urlParams, setUrlParams] = useURLParams({ deletePage: true })

  return {
    initialFetch: (event = EVENT_TYPES.DEFAULT, replaceURL = true) => {
      const params = deepMerge(defaultParams, urlParams, { arrayMerge: mergeArray })

      setUrlParams(params, replaceURL)
      return fetchFunction({ event, params })
    },
    updateData: (event, params, replaceURL = true) => {
      setUrlParams(params, replaceURL)
      return fetchFunction({ event, params })
    }
  }
}
