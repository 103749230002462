import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { useSelector } from 'react-redux'
import { TextField } from '@beetrack/hp-components'

import { getDark } from '~selectors/layout'

const FormikTextField = (props) => {
  const { onChange, disabled, ...otherProps } = props
  const dark = useSelector(getDark)

  return (
    <Field name={props.name}>
      {({ field, form, meta }) => {
        const handleEventChange = (event) => {
          form.handleChange(event)
          const { name, value } = event.target
          onChange(name, value)
        }
        const fieldDisabled = disabled || form.isSubmitting
        const hasInteraction = !fieldDisabled && (meta.touched || form.submitCount > 0)
        const cleanValue = [undefined, null].includes(field.value) ? '' : field.value

        return (
          <TextField
            dark={dark}
            onChange={handleEventChange}
            onBlur={form.handleBlur}
            value={cleanValue}
            error={hasInteraction && !!meta.error}
            helperText={(hasInteraction && meta.error) || ''}
            {...otherProps}
            disabled={fieldDisabled}
          />
        )
      }}
    </Field>
  )
}

FormikTextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

FormikTextField.defaultProps = {
  type: 'text',
  onChange: () => {},
  disabled: false
}

export default FormikTextField
