import { createAsyncThunk } from '@reduxjs/toolkit'

import * as api from '~api/settings/account'
import normalizeJsonAPI from '@beetrack/jsonapi-normalizer'
import i18next from '~localization'

import {
  parseAccountRequest,
  isAccountPictureUpdated,
  parseAccountPictureRequest,
  parseAccountCountriesOptions,
  parseAccountTimezoneOptions,
  parseAccountLanguagesOptions,
  parseAccountError,
  parseAccountErrorForm
} from '~parsers/settings/account'

import { openSnackbar } from '~actions/layout'
import { ENTITY } from '~selectors/entities'
import i18n from '~localization/index'

export const fetchAccount = createAsyncThunk(`${ENTITY.account}/fetchAccount`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.fetchAccount()

      const { data = {} } = response

      const normalizedData = normalizeJsonAPI(data, true)

      return { ...normalizedData, meta: data?.data?.meta }
    } catch (error) {
      return rejectWithValue(parseAccountError(error, 'fetchAccount'))
    }
  }
)

export const updateAccount = createAsyncThunk(
  `${ENTITY.account}/updateAccount`,
  async (account, { dispatch, rejectWithValue }) => {
    try {
      let updatedAccountPictureURL

      const accountPictureUpdated = isAccountPictureUpdated(account)

      if (accountPictureUpdated) {
        const image = parseAccountPictureRequest(account)

        const updatedPictureResponse = await api.updatePicture(image)

        updatedAccountPictureURL = updatedPictureResponse?.data?.response
      }

      const response = await api.updateAccount(parseAccountRequest(account, updatedAccountPictureURL))

      const { data = {} } = response

      const normalizedData = normalizeJsonAPI(data, true)

      i18next.changeLanguage(account.language)

      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:account.update-success')
      }))

      dispatch(fetchAccountLanguageOptions())

      return normalizedData
    } catch (error) {
      console.error(error)

      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:account.update-error')
      }))

      return rejectWithValue(parseAccountErrorForm(error?.response?.data))
    }
  }
)

export const updateAccountPicture = createAsyncThunk(
  `${ENTITY.account}/updateAccountPicture`,
  async (image) => {
    const response = await api.updatePicture(image)

    const { data } = response
    return data.response
  }
)

export const fetchAccountCountryOptions = createAsyncThunk(`${ENTITY.account}/fetchAccountCountryOptions`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.fetchAccountCountries()

      const { data = {} } = response

      const parsedData = parseAccountCountriesOptions(data)

      return parsedData
    } catch (error) {
      console.error(error)

      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:account.update-error')
      }))

      return rejectWithValue(parseAccountError(error, 'fetchCountryOptions'))
    }
  }
)

export const fetchAccountLanguageOptions = createAsyncThunk(`${ENTITY.account}/fetchAccountLanguageOptions`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.fetchAccountLanguages()

      const { data = {} } = response

      const parsedData = parseAccountLanguagesOptions(data)

      return parsedData
    } catch (error) {
      console.error(error)

      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:account.update-error')
      }))

      return rejectWithValue(parseAccountError(error, 'fetchLanguageOptions'))
    }
  }
)

export const fetchAccountTimezoneOptions = createAsyncThunk(`${ENTITY.account}/fetchAccountTimezoneOptions`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.fetchAccountTimezones()

      const { data = {} } = response

      const parsedData = parseAccountTimezoneOptions(data)

      return parsedData
    } catch (error) {
      console.error(error)

      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:account.update-error')
      }))

      return rejectWithValue(parseAccountError(error, 'fetchTimezoneOptions'))
    }
  }
)
