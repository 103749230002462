import * as api from '~api/settings/sub_statuses'
import { createAsyncThunk } from '@reduxjs/toolkit'
import normalizeJsonAPI from '@beetrack/jsonapi-normalizer'
import i18n from '~localization/index'
import deepMerge from 'deepmerge'

import { openSnackbar } from '~actions/layout'

import { parseFetchResponse } from '~parsers'
import { EVENT_TYPES } from 'frontend/constants'
import { parsePaginationAndMeta, parseResponseErrors } from 'frontend/parsers'
import { parseStatusesRequest } from 'frontend/parsers/settings/sub_statuses'

const NAMESPACE = 'SUBSTATUSES'

export const fetchSubStatuses = createAsyncThunk(
  `${NAMESPACE}/fetchSubStatuses`,
  async (args, { rejectWithValue }) => {
    try {
      const response = await api.fetchSubStatuses(args.params)
      const normalized = parseFetchResponse(response)
      return normalized
    } catch (error) {
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const deleteSubStatus = createAsyncThunk(
  `${NAMESPACE}/deleteSubStatus`,
  async ({ deleteItems, visiblePages, params = {} }, { dispatch, rejectWithValue }) => {
    const [id] = deleteItems.map(({ item }) => item.id)

    try {
      await api.deleteSubStatus(id)
      const promises = visiblePages.map(page => api.fetchSubStatuses({ ...params, page }))
      const responses = await Promise.all(promises)
      const data = deepMerge.all(responses.map(response => parseFetchResponse(response)))
      data.pagination = parsePaginationAndMeta(responses[0].data).pagination

      dispatch(fetchSubStatuses.fulfilled(data))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:sub-statuses.messages.delete-success')
      }))
    } catch (error) {
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:sub-statuses.messages.delete-error')
      }))
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const createSubStatusesDefault = createAsyncThunk(
  `${NAMESPACE}/createSubStatusesDefault`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await api.createSubStatusesDefault()
      dispatch(fetchSubStatuses({ event: EVENT_TYPES.DEFAULT }))
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:sub-statuses.create-suggested.success')
      }))
    } catch (error) {
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:sub-statuses.create-suggested.error')
      }))
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const fetchSubStatusesStatuses = createAsyncThunk(
  `${NAMESPACE}/fetchSubStatusesStatuses`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchSubStatusesStatuses()
      const normalized = parseStatusesRequest(response)
      return normalized
    } catch (error) {
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const fetchSubStatusesFormats = createAsyncThunk(
  `${NAMESPACE}/fetchSubStatusesFormats`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchSubStatusesFormats()
      const normalized = parseStatusesRequest(response)
      return normalized
    } catch (error) {
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const fetchSubStatusesModes = createAsyncThunk(
  `${NAMESPACE}/fetchSubStatusesModes`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchSubStatusesModes()
      const normalized = parseStatusesRequest(response)
      return normalized
    } catch (error) {
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const createSubStatus = createAsyncThunk(
  `${NAMESPACE}/createSubStatus`,
  async ({ params }, { dispatch, rejectWithValue }) => {
    try {
      await api.createSubStatus(params)
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:sub-statuses-form.messages.create-success')
      }))
    } catch (error) {
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:sub-statuses-form.messages.create-error')
      }))
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const getSubStatus = createAsyncThunk(
  `${NAMESPACE}/getSubStatus`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.getSubStatus(id)
      const normalized = normalizeJsonAPI(response.data)
      return normalized
    } catch (error) {
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)

export const updateSubStatus = createAsyncThunk(
  `${NAMESPACE}/updateSubStatus`,
  async ({ id, params }, { dispatch, rejectWithValue }) => {
    try {
      await api.updateSubStatus(id, params)
      dispatch(openSnackbar({
        type: 'success',
        message: i18n.t('pages:sub-statuses-form.messages.update-success')
      }))
    } catch (error) {
      dispatch(openSnackbar({
        type: 'error',
        message: i18n.t('pages:sub-statuses-form.messages.update-error')
      }))
      return rejectWithValue(parseResponseErrors(error))
    }
  }
)
