import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { useSelector } from 'react-redux'

import { TimePicker } from '@beetrack/hp-components'

import { getDark } from '~selectors/layout'

const FormikTimePicker = (props) => {
  const { name, label, disabled, ...otherProps } = props
  const dark = useSelector(getDark)

  return (
    <Field name={name}>
      {({ form, field, meta }) => {
        const { setFieldValue } = form
        const { error, touched } = meta
        const fieldDisabled = disabled || form.isSubmitting
        const hasInteraction = !fieldDisabled && (touched || form.submitCount > 0)

        return (
          <TimePicker
            dark={dark}
            label={label}
            time={field?.value || ''}
            onChange={(value) => setFieldValue(name, value)}
            error={hasInteraction && !!error}
            helperText={(hasInteraction && error) || ''}
            disabled={fieldDisabled}
            {...otherProps}
          />
        )
      }}
    </Field>
  )
}

FormikTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

FormikTimePicker.defaultProps = {
  disabled: false
}

export default FormikTimePicker
