import { store } from '~reducers/index'
import { selectWebhookResources } from '~selectors/settings/webhooks'
import { capitalize } from '~utils'

export const parseWebhookRequest = (formValues) => {
  const { resources } = selectWebhookResources(store.getState())
  const parsedHeaders = {}

  formValues.headers.forEach(({ key, value }) => {
    parsedHeaders[key] = value
  })

  const resourcesAttibutes = Object.keys(formValues.events).map(resource => (
    Object.keys(formValues.events[resource]).map(event => {
      const resourceEvent = formValues.events[resource][event]
      let extraAttribute = {}
      resources[resource].extra_attributes.map(extra => {
        extraAttribute = {
          ...extraAttribute,
          [extra]: resourceEvent.extraParametter?.[extra] || false
        }
      })

      return {
        id: resourceEvent.id,
        resource,
        event,
        _destroy: resourceEvent.id && !resourceEvent[event],
        extra_attributes: extraAttribute
      }
    })
  )).flat()

  return {
    outbound_configuration: {
      url: formValues.url,
      headers: JSON.stringify(parsedHeaders),
      outbound_configuration_resources_attributes: resourcesAttibutes
    }
  }
}

export const parseWebhookErrors = ({ resource: errorResponse } = {}) => {
  return {
    ...errorResponse?.url && { url: errorResponse.url.map(capitalize).join('. ') }
  }
}
