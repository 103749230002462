import axios from '../config'

export const fetchAccount = () => axios.get(`/account`, {})

export const updateAccount = (account) => axios.post('/settings/account.json', account)

export const fetchAccountCountries = () => axios.get(`/account/countries`, {})

export const fetchAccountLanguages = () => axios.get(`/account/languages`, {})

export const fetchAccountTimezones = () => axios.get(`/account/timezones`, {})

export const updatePicture = img => axios.post('/utils/upload_photo/account', img)
