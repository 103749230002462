import { createReducer } from '@reduxjs/toolkit'
import deepMerge from 'deepmerge'

import { storeEntities, replaceEntities } from '~actions/entities'

const overwriteMerge = (_destinationArray, sourceArray) => sourceArray

const setEntities = (state, action) => {
  return deepMerge(state, action?.payload?.entities || {}, { arrayMerge: overwriteMerge })
}

const INITIAL_STATE = {}

const isFulfilledWithEntities = action => action?.meta?.requestStatus === 'fulfilled' && action?.payload?.entities

export const entitiesReducer = createReducer(INITIAL_STATE, builder =>
  builder
    .addCase(storeEntities, setEntities)
    .addCase(replaceEntities, (state, action) => {
      const { entities } = action.payload
      Object.keys(entities).forEach(entity => {
        Object.keys(entities[entity]).forEach(id => {
          state[entity][id] = entities[entity][id]
        })
      })
    })
    .addMatcher(isFulfilledWithEntities, setEntities)
)

export default entitiesReducer
