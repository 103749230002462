import axios from '../config'

export const fetchPlaces = (params) => {
  return axios.get(`/account/places`, {
    params
  })
}

export const deletePlace = (id) => {
  return axios.delete(`/settings/places/${id}`)
}

export const createPlace = (params) => {
  return axios.post(`/settings/places`, { place: params }).catch(error => { throw error })
}

export const getPlace = (id) => {
  return axios.get(`/account/places/${id}`).catch(error => { throw error })
}

export const updatePlace = (id, params) => {
  return axios.patch(`/settings/places/${id}`,
    params).catch(error => { throw error })
}
