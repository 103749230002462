import DirectionsAgnostic from '@beetrack/directions-agnostic'

export const simulationBetweenTwoPoints = async (start, end) => {
  const simulation = new DirectionsAgnostic(start, end)
  const result = await simulation.request()

  const steps = result.jsonResponse.legs[0].steps.filter(step => step.duration > 0)
  return steps.map(step => {
    const waypointDuration = (step.duration / step.waypoints.length) * 1000
    return step.waypoints.map(stepWaypoint => ({
      ...stepWaypoint,
      duration: waypointDuration
    }))
  }).flat()
}
