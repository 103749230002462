import React from 'react'
import { useSelector } from 'react-redux'
import { LoadingPage, NoPermissionState, hpUtils } from '@beetrack/hp-components'

import LastmileSettingsSidebar from '.'
import { getSettingsSidebarData, hasSettingsModulePermission } from '~selectors/layout'

export const withSettingsSidebar = (PageComponent, moduleName) => () => {
  const { dark, hasSettingsPermission, isLoading } = useSelector(getSettingsSidebarData)
  const showSettingsSidebar = isLoading || hasSettingsPermission
  const hasModulePermission = useSelector(hasSettingsModulePermission(moduleName))

  return (
    <div className='with-settings-sidebar'>
      {showSettingsSidebar && (
        <div className='with-settings-sidebar__sidebar'>
          <LastmileSettingsSidebar />
        </div>
      )}
      <div
        className={hpUtils.getBEMClasses(
          'with-settings-sidebar__content', {
            'hidden-sidebar': !showSettingsSidebar
          }
        )}
      >
        {isLoading ? (
          <LoadingPage dark={dark} />
        ) : (!hasSettingsPermission || !hasModulePermission) ? (
          <NoPermissionState dark={dark} />
        ) : (
          <PageComponent />
        )}
      </div>
    </div>
  )
}

export default withSettingsSidebar
