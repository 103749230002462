import { createReducer } from '@reduxjs/toolkit'
import { fetchWaypoints, resetIdsWaypoint, setDispatchGuide, setOrderSelected,
  setStepIndex, setWidgetConfig, setWidgetKey } from '~actions/widget_result'

const INITIAL_STATE = {
  dispatchGuide: [],
  orderSelected: undefined,
  widgetConfig: {},
  widgetKey: undefined,
  stepIndex: 0,
  idsWaypoint: []
}

export const widgetResultReducer = createReducer(INITIAL_STATE, (builder) =>
  builder
    .addCase(setDispatchGuide, (state, { payload }) => {
      state.dispatchGuide = payload
    })
    .addCase(setWidgetConfig, (state, { payload }) => {
      state.widgetConfig = payload
    })
    .addCase(setWidgetKey, (state, { payload }) => {
      state.widgetKey = payload
    })
    .addCase(setOrderSelected, (state, { payload }) => {
      state.orderSelected = payload
    })
    .addCase(setStepIndex, (state, { payload }) => {
      state.stepIndex = payload
    })
    .addCase(fetchWaypoints.fulfilled, (state, { payload }) => {
      state.idsWaypoint = [...new Set([...state.idsWaypoint, ...payload.result.waypoint || []])]
    })
    .addCase(resetIdsWaypoint, (state, { payload }) => {
      state.idsWaypoint = []
    })
)

export default widgetResultReducer
