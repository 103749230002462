import components from './en/components.json'
import general from './en/general.json'
import models from './en/models.json'
import pages from './en/pages.json'
import validations from './en/validations.json'

const resource = {
  components,
  general,
  models,
  pages,
  validations
}

export default resource
