import i18n from 'i18next'
import resources from './resources/index'
import { capitalize } from '~utils'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['htmlTag', 'navigator']
    },
    debug: process.env.NODE_ENV !== 'test',
    lng: document.documentElement.lang || 'es',
    fallbackLng: 'en',
    resources,
    partialBundledLanguages: true,
    interpolation: {
      format: function (value, format, lng) {
        if (format === 'capitalize') return capitalize(value)
        return value
      }
    }
  })

export default i18n
