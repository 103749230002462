import normalizeJsonAPI from '@beetrack/jsonapi-normalizer'
import { HTTP_ERRORS } from 'frontend/constants'

import { objectKeysToCamelCase } from '~utils'

export const parseFetchResponse = ({ data }) => {
  const { result, entities } = normalizeJsonAPI(data, true)
  return {
    ids: Object.values(result)[0],
    entities,
    ...parsePaginationAndMeta(data)
  }
}

export const parsePaginationAndMeta = data => {
  const {
    current_page: currentPage,
    total_pages: totalPages,
    total_elements: totalItems,
    objects_per_page: pageSize,
    ...meta
  } = data.meta

  return {
    pagination: {
      currentPage,
      totalPages,
      totalItems,
      pageSize
    },
    meta: objectKeysToCamelCase(meta)
  }
}

export const parseResponseErrors = error => {
  const { response } = error
  const { status, data = {} } = response

  if (status !== HTTP_ERRORS.UNPROCESSABLE_ENTITY) return { status, data: data.error || data }

  const { message = {} } = data

  const parsedErrors = Object.entries(message).map(([key, value]) => [key, value[0]])

  return Object.fromEntries(parsedErrors)
}
