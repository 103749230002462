import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Marker, SVGMarkers } from '@beetrack/hp-components'

import { selectAddress, selectOrderSelected, selectWidgetKey } from '~selectors/widget_result'
import { fetchWaypoints } from '~actions/widget_result'

import { usePolling } from '~hooks/usePolling'

import PageMap from '~components/shared/page_map'
import { WidgetResultDriverSimulator } from '.'
import { COLOR_POINT_FINAL_DESTINATION } from 'frontend/constants/map'

const WidgetResultMap = (props) => {
  const { addressId, dispatchId, statusId = 0, ...otherProps } = props
  const orderSelected = useSelector(selectOrderSelected)
  const address = useSelector(selectAddress(addressId))
  const key = useSelector(selectWidgetKey)
  const dispatch = useDispatch()
  const waypointPolling = usePolling({
    callback: ({ dispatchId, lastWaypointId }) => dispatch(fetchWaypoints({ key, dispatchId, lastWaypointId })),
    time: 60000
  })

  useEffect(() => {
    const lastWaypoint = JSON.parse(window.localStorage.getItem('lastWaypoint'))
    if (orderSelected === lastWaypoint?.orderSelectedId) {
      waypointPolling.init({ dispatchId: dispatchId, lastWaypointId: lastWaypoint?.idWaipoint })
    } else {
      waypointPolling.init({ dispatchId })
    }

    return () => {
      waypointPolling.clear()
    }
  }, [orderSelected])

  return (
    <PageMap additionalClassName='widget-result__map'>
      {address.latitude && address.longitude && (
        <Marker
          key={statusId}
          id={address?.id}
          position={{ lat: address.latitude, lng: address.longitude }}
          icon={<SVGMarkers.Pin color={COLOR_POINT_FINAL_DESTINATION[statusId]} />}
          {...otherProps} />
      )}
      <WidgetResultDriverSimulator key={orderSelected} />
    </PageMap>
  )
}

export default WidgetResultMap
